import { ReachAnalysis2Api } from '@/api';
import {
  ReachAnalysisProject2BasicInfo,
  ReachAnalysisProject2CardDetailCostTypeEnum,
  ReachAnalysisProject2CardStatusReasonEnum,
  ReachAnalysisProject2Kpi,
  ReachAnalysisProject2SectionStatusCardsCalculateStatusEnum,
  ReachAnalysisProject2StatusCalculateReasonEnum,
  ReachAnalysisProject2StatusStatusEnum,
  WorkspaceWorkspaceTypeEnum
} from '@/api/openapi';
import { httpCode } from '@/common/constant';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import { useWorkspaceType } from '@/composables/workspace';
import { COMPANY_ROUTES, REACH_ANALYSIS_ROUTES, ROUTE_NAMES } from '@/router';
import { useReachAnalysisStore } from '@/store/reachAnalysis';
import { useSidebarStore } from '@/store/sidebar';
import { getHours, isAfter, subDays } from 'date-fns';
import { Ref, computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

interface Result {
  breadcrumbs: Ref<{
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  }>;
  isCmCompany: Ref<boolean>;
  projectData: Ref<ReachAnalysisProject2BasicInfo | undefined>;
  isFetchProject: Ref<boolean>;
  kpiData: Ref<ReachAnalysisProject2Kpi | undefined>;
  isFuture: Ref<boolean>;
  isSetPerCost: Ref<boolean>;
  isMainCardError: Ref<boolean>;
  isFetchKPI: Ref<boolean>;
  updateProjectName: (params: string) => Promise<void>;
  isUpdateProjectName: Ref<boolean>;
  checkReports: (params: unknown) => Promise<void>;
  isCheckReports: Ref<boolean>;
  fetchProjectStatus: (params: boolean) => Promise<void>;
  isFetchProjectStatus: Ref<boolean>;
  intervalId: Ref<number | undefined>;
  resetState: () => void;
  isStopLoopRequest: Ref<boolean>;
  loopRequest: () => void;
  stopLoopRequest: (params: number) => void;
  isKpiLoading: Ref<boolean>;
  stopWatch: () => void;
  init: () => Promise<void>;
}

const BREADCRUMBS = {
  parents: [
    { name: COMPANY_ROUTES.top, label: 'ホーム' },
    { name: '', label: 'エフェクティブリーチ' },
    {
      name: REACH_ANALYSIS_ROUTES.list,
      label: 'リーチ分析'
    }
  ],
  current: { label: '結果' }
};

export const useResult = (): Result => {
  const route = useRoute();
  const router = useRouter();
  const breadcrumbs = ref(BREADCRUMBS);
  const projectId = route.params.projectId?.toString();
  const isCmCompany = ref(false);
  const sidebarStore = useSidebarStore();
  const rAnalysisStore = useReachAnalysisStore();
  const projectData: Ref<ReachAnalysisProject2BasicInfo | undefined> = ref();
  const kpiData: Ref<ReachAnalysisProject2Kpi | undefined> = ref();
  const isCalculating = ref(false);

  const { getCurrentWorkspaceType } = useWorkspaceType();
  const getIsCmCompany = async () => {
    const workspaceType = await getCurrentWorkspaceType();
    isCmCompany.value = workspaceType === WorkspaceWorkspaceTypeEnum.CmCompany;
  };

  const mainCard = computed(() => {
    return rAnalysisStore.mainCard();
  });

  const isFuture = computed(() => {
    const today = new Date();
    const hour = getHours(today);
    const finalReportDay =
      0 <= hour && hour < 5 ? subDays(today, 4) : subDays(today, 3);
    if (!mainCard.value) return false;
    const end = new Date(mainCard.value.basicInfo.endDate);
    return isAfter(end, finalReportDay);
  });

  const isSetPerCost = computed(() => {
    if (!mainCard.value) return false;
    return (
      mainCard.value.basicInfo.costType ===
      ReachAnalysisProject2CardDetailCostTypeEnum.PerCost
    );
  });

  const isMainCardError = computed(() => {
    if (!mainCard.value) return true;
    if (!mainCard.value?.cardStatus.reason) return false;
    return [
      ReachAnalysisProject2CardStatusReasonEnum.AreaContractTerminated,
      ReachAnalysisProject2CardStatusReasonEnum.TargetInvalidConditionsIncluded,
      ReachAnalysisProject2CardStatusReasonEnum.TargetDeleted
    ].includes(mainCard.value.cardStatus.reason);
  });

  const _fetchProject = async () => {
    try {
      if (!projectId) return false;

      const res = await ReachAnalysis2Api.getReachAnalysisProjects2ReachAnalysisProject2Id(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        if (!projectData.value) {
          projectData.value = res.data;
        } else {
          projectData.value.updatedAt = res.data.updatedAt;
        }
        setProjectTitle(projectData?.value?.name || '');
        return true;
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの取得に失敗しました',
          variant: 'error'
        });
        return false;
      }
    } catch (e) {
      if (e.status === httpCode.forbidden) {
        await router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
        return false;
      }
      if (e.response.status === httpCode.notFound) {
        await router.push({
          name: ROUTE_NAMES.notFound,
          params: { backTop: 'true' },
          force: true
        });
        return false;
      }
      toast({
        title: '失敗',
        message: 'プロジェクトの取得に失敗しました',
        variant: 'error'
      });
      return false;
    }
  };
  const [isFetchProject, fetchProject] = useLoading(_fetchProject);

  const _fetchKPI = async () => {
    try {
      if (!projectId) return;

      const res = await ReachAnalysis2Api.getReachAnalysisProjects2ReachAnalysisProject2IdKpi(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        kpiData.value = res.data;
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトのKPIデータ取得に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: 'プロジェクトのKPIデータ取得に失敗しました',
        variant: 'error'
      });
    }
  };
  const [isFetchKPI, fetchKPI] = useLoading(_fetchKPI);

  const _updateProjectName = async (projectName: string) => {
    if (!projectId || !projectData.value) return;
    const oldName = projectData.value.name;
    try {
      projectData.value.name = projectName;
      const name = { projectName: projectName };
      const res = await ReachAnalysis2Api.putReachAnalysisProjects2ReachAnalysisProject2IdProjectName(
        Number(projectId),
        name
      );
      if (200 <= res.status && res.status < 300) {
        setProjectTitle(res.data.projectName || '');
      } else {
        projectData.value.name = oldName;
        toast({
          title: '失敗',
          message: 'プロジェクトのタイトル変更に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      projectData.value.name = oldName;
      toast({
        title: '失敗',
        message: 'プロジェクトのタイトル変更に失敗しました',
        variant: 'error'
      });
    }
  };
  const [isUpdateProjectName, updateProjectName] = useLoading(
    _updateProjectName
  );

  const _checkReports = async () => {
    try {
      if (!projectId) return;
      const res = await ReachAnalysis2Api.postReachAnalysisProjects2ReachAnalysisProject2IdCheckReports(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        fetchProjectStatus(true);
      } else {
        toast({
          title: '失敗',
          message: 'レポートの更新チェックに失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: 'レポートの更新チェックに失敗しました',
        variant: 'error'
      });
    }
  };
  const [isCheckReports, checkReports] = useLoading(_checkReports);

  const resetState = () => {
    rAnalysisStore.resetState();
  };

  // ステータス取得ループ処理関連
  const intervalId = ref();
  const isStopLoopRequest = ref(false);
  const stopLoopRequest = id => {
    clearInterval(id);
  };
  const loopRequest = async () => {
    intervalId.value = setTimeout(async () => {
      await fetchProjectStatus(true);
    }, 3000);
  };

  const _fetchProjectStatus = async (isLoop = true) => {
    try {
      if (!projectId) return;
      const res = await ReachAnalysis2Api.getReachAnalysisProjects2ReachAnalysisProject2IdStatus(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        rAnalysisStore.setProjectStatus(res.data);
        checkStatus(isLoop);
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトステータス取得に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: 'プロジェクトステータス取得に失敗しました',
        variant: 'error'
      });
    } finally {
      setTimeout(async () => {
        if (isStopLoopRequest.value) stopLoopRequest(intervalId.value);
      }, 1000);
    }
  };
  const [isFetchProjectStatus, fetchProjectStatus] = useLoading(
    _fetchProjectStatus
  );

  const showToast = async () => {
    // ステータスが参照可能な場合、プロジェクト情報を取得する
    if (
      rAnalysisStore.projectStatus?.status ===
      ReachAnalysisProject2StatusStatusEnum.Referable
    ) {
      fetchProject('');
      return;
    }

    const editSettingReasons = [
      ReachAnalysisProject2StatusCalculateReasonEnum.UpdateCustomTarget,
      ReachAnalysisProject2StatusCalculateReasonEnum.ProductMerge,
      ReachAnalysisProject2StatusCalculateReasonEnum.ChangeMapping,
      ReachAnalysisProject2StatusCalculateReasonEnum.ChangeConversion15Sec
    ];

    const calculateReason = rAnalysisStore.projectStatus?.calculateReason;

    // ステータスが計算中、かつ理由が設定変更の場合、再集計中である旨を通知する
    if (
      rAnalysisStore.projectStatus?.status ===
        ReachAnalysisProject2StatusStatusEnum.Calculating &&
      calculateReason &&
      editSettingReasons.includes(calculateReason)
    ) {
      toast({
        title: '設定の変更を検知しました',
        message: '再集計しています。しばらくお待ちください。',
        variant: 'attention'
      });
    }
  };

  const checkStatus = (isLoop = true) => {
    // ループ停止判定
    if (
      rAnalysisStore.projectStatus?.status ===
        ReachAnalysisProject2StatusStatusEnum.Calculating &&
      isLoop
    ) {
      loopRequest();
    } else {
      stopLoopRequest(intervalId.value);
    }
  };

  const setProjectTitle = (title: string) => {
    // ブラウザタイトルにプロジェクト名を追加
    document.title = title + ' | ' + 'リーチ分析' + ' | ' + 'TVAL';
  };

  const watchKpiStatus = watch(
    () => rAnalysisStore.kpiStatus,
    status => {
      isCalculating.value =
        status !==
          ReachAnalysisProject2SectionStatusCardsCalculateStatusEnum.Referable &&
        status !==
          ReachAnalysisProject2SectionStatusCardsCalculateStatusEnum.Failed;

      if (
        status ===
        ReachAnalysisProject2SectionStatusCardsCalculateStatusEnum.Referable
      ) {
        fetchKPI('');
      }
    }
  );

  const watchProjectStatus = watch(
    () => rAnalysisStore.projectStatus?.status,
    () => {
      showToast();
    }
  );

  const stopWatch = () => {
    watchKpiStatus();
    watchProjectStatus();
  };

  const isKpiLoading = computed(() => {
    return isFetchKPI.value || !kpiData.value || isCalculating.value;
  });

  const init = async () => {
    rAnalysisStore.resetState();
    sidebarStore.hide();
    const isSuccess = await fetchProject('');
    if (!isSuccess) return;
    getIsCmCompany();
    checkReports('');
  };

  return {
    breadcrumbs,
    isCmCompany,
    projectData,
    isFetchProject,
    kpiData,
    isFuture,
    isSetPerCost,
    isMainCardError,
    isFetchKPI,
    updateProjectName,
    isUpdateProjectName,
    checkReports,
    isCheckReports,
    fetchProjectStatus,
    isFetchProjectStatus,
    intervalId,
    resetState,
    isStopLoopRequest,
    loopRequest,
    stopLoopRequest,
    isKpiLoading,
    stopWatch,
    init
  };
};
