import {
  ReachAnalysisProject2CardConditionFrom,
  ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum,
  ReachAnalysisProject2CardConditionFromCmBuyingKindEnum,
  ReachAnalysisProject2CardConditionFromColorEnum,
  ReachAnalysisProject2CardConditionFromCostTypeEnum,
  ReachAnalysisProject2CardForm,
  ReachAnalysisProject2CardListCards,
  ReachAnalysisProject2TargetSettings,
  ReachAnalysisProject2TargetSettingsTargetTypeEnum
} from '@/api/openapi';
import { TARGET_NAME } from '@/common/constant';
import { PeriodListType } from '@/common/period';
import { VALIDATION_MESSAGE } from '@/common/validation';
import { FormValueType } from '@/composables/reachanalysis/reachAnalysisForm';
import { Ref, computed, ref, shallowRef } from 'vue';

interface Create {
  formValue: Ref<FormValueType | undefined>;
  cardLabel: Ref<string>;
  cardColor: Ref<ValueOf<typeof CardColor>>;
  isSubMenuOpen: Ref<boolean>;
  doSubmit: () => void;
  validLabel: Ref<string | undefined>;
  isSubmitButtonDisabled: Ref<boolean>;
}

const convertToCmType = (
  type: 'TIME' | 'SPOT' | undefined
): ReachAnalysisProject2CardConditionFromCmBuyingKindEnum => {
  switch (type) {
    case 'TIME':
      return ReachAnalysisProject2CardConditionFromCmBuyingKindEnum.Time;
    case 'SPOT':
      return ReachAnalysisProject2CardConditionFromCmBuyingKindEnum.Spot;
    default:
      return ReachAnalysisProject2CardConditionFromCmBuyingKindEnum.All;
  }
};

const convertToTargetType = (
  name: string
): ReachAnalysisProject2TargetSettingsTargetTypeEnum => {
  switch (name) {
    case TARGET_NAME.individual:
    case TARGET_NAME.household:
    case TARGET_NAME.individualAndHousehold:
      return ReachAnalysisProject2TargetSettingsTargetTypeEnum.Individual;
    case TARGET_NAME.genderAge12Type:
      return ReachAnalysisProject2TargetSettingsTargetTypeEnum.BasicGa12;
    case TARGET_NAME.gender10Type:
      return ReachAnalysisProject2TargetSettingsTargetTypeEnum.BasicGa10S;
    case TARGET_NAME.customTarget:
      return ReachAnalysisProject2TargetSettingsTargetTypeEnum.CustomTarget;
    default:
      return ReachAnalysisProject2TargetSettingsTargetTypeEnum.Individual;
  }
};

const convertToReachAnalysisColor = (
  color: ValueOf<typeof CardColor>
): ReachAnalysisProject2CardConditionFromColorEnum => {
  switch (color) {
    case CardColor.Blue:
      return ReachAnalysisProject2CardConditionFromColorEnum.Blue;
    case CardColor.Sky:
      return ReachAnalysisProject2CardConditionFromColorEnum.Sky;
    case CardColor.Green:
      return ReachAnalysisProject2CardConditionFromColorEnum.Green;
    case CardColor.Yellow:
      return ReachAnalysisProject2CardConditionFromColorEnum.Yellow;
    case CardColor.Orange:
      return ReachAnalysisProject2CardConditionFromColorEnum.Orange;
    case CardColor.Pink:
      return ReachAnalysisProject2CardConditionFromColorEnum.Pink;
    case CardColor.Purple:
      return ReachAnalysisProject2CardConditionFromColorEnum.Purple;
    default:
      return ReachAnalysisProject2CardConditionFromColorEnum.Blue;
  }
};

const convertToPeriodModeEnum = (
  val: PeriodListType | undefined
): ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum => {
  if (!val) {
    return ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum.Manual;
  }
  switch (val?.key) {
    case 'lastQuarter':
      return ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum.PreviousQuarter;
    case 'lastCools':
      return ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum.PreviousSeason;
    case 'currentCools':
      return ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum.CurrentSeason;
    case 'lastMonth':
      return ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum.PreviousMonth;
    case 'fourWeeksAgo':
      return ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum.Previous4Weeks;
    case 'lastWeek':
      return ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum.PreviousWeek;
    case 'currentMonth':
      return ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum.CurrentMonth;
    case 'lastTwentyEightDays':
      return ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum.Last28Days;
    case 'lastFourteenDays':
      return ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum.Last14Days;
    case 'referMain':
      return ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum.MainCard;
    case 'custom':
      return ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum.Auto;
    default:
      return ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum.Manual;
  }
};

type ValueOf<T> = T[keyof T];
export const CardColor = {
  Blue: 'BLUE',
  Sky: 'SKY',
  Green: 'GREEN',
  Yellow: 'YELLOW',
  Orange: 'ORANGE',
  Pink: 'PINK',
  Purple: 'PURPLE'
} as const;

export const useSettingCardCreateEditModal = (
  editCardData: ReachAnalysisProject2CardListCards | undefined,
  defaultColor: string | undefined,
  submit: (cardForm: ReachAnalysisProject2CardForm) => void
): Create => {
  const formValue: Ref<FormValueType | undefined> = shallowRef();
  const cardLabel = ref('');
  const cardColor: Ref<ValueOf<typeof CardColor>> = ref(CardColor.Blue);
  const isSubMenuOpen = ref(false);

  if (defaultColor) {
    cardColor.value = defaultColor as ValueOf<typeof CardColor>;
  }

  if (editCardData) {
    isSubMenuOpen.value = true;
    cardLabel.value = editCardData.basicInfo.label;
    cardColor.value = editCardData.basicInfo.color;
  }

  const setApiData = () => {
    if (!formValue.value) return;
    const form = formValue.value;
    if (
      !form.areaValue.value ||
      !form.startDateStr.value ||
      !form.endDateStr.value ||
      !form.products.value
    ) {
      return;
    }

    let placementCost: number | undefined = undefined;
    let perCost: number | undefined = undefined;
    switch (form.costType.value) {
      case ReachAnalysisProject2CardConditionFromCostTypeEnum.PlacementCost:
        placementCost = form.costNum.value;
        break;
      case ReachAnalysisProject2CardConditionFromCostTypeEnum.PerCost:
        perCost = form.costNum.value;
        break;
      default:
        break;
    }
    const costType =
      form.costNum.value ||
      form.costType.value ===
        ReachAnalysisProject2CardConditionFromCostTypeEnum.ReferMainPerCost
        ? form.costType.value
        : undefined;

    const basicInfo: ReachAnalysisProject2CardConditionFrom = {
      label: cardLabel.value,
      areaCode: form.areaValue.value,
      calenderPeriodMode: convertToPeriodModeEnum(form.periodShortcut.value),
      startDate: form.startDateStr.value,
      endDate: form.endDateStr.value,
      cmSponsorIds: form.sponsorsIds.value,
      products: form.products.value,
      cmCreativeIds: form.selectedCmCreativeIds.value,
      cmBuyingKind: convertToCmType(form.cmTypeValue.value),
      numOfEffectiveContacts: form.numOfEffectiveContacts.value,
      placementCost: placementCost,
      perCost: perCost,
      costType: costType,
      color: convertToReachAnalysisColor(cardColor.value)
    };

    const targetList =
      form.targetName.value === TARGET_NAME.customTarget
        ? form.customTargetId.value
        : form.basicTargetIds.value;
    const targets = targetList.map(v => ({
      targetId: v,
      targetName: form?.targetName.value
    }));

    const targetSetting: ReachAnalysisProject2TargetSettings = {
      targetType: convertToTargetType(form.targetName.value),
      targets: targets
    };

    return {
      basicInfo,
      targetSetting
    };
  };

  const doSubmit = () => {
    const reachAnalysisProject2CardForm = setApiData();
    if (reachAnalysisProject2CardForm) submit(reachAnalysisProject2CardForm);
  };

  const validLabel: Ref<string | undefined> = computed(() => {
    if (cardLabel.value.length > 22) {
      return VALIDATION_MESSAGE.max22;
    }
    if (cardLabel.value.length && !cardLabel.value.match(/\S/g)) {
      return VALIDATION_MESSAGE.invalidChar;
    }
    return undefined;
  });

  const isSubmitButtonDisabled = computed(() => {
    const form = formValue.value;
    return !form ||
      !form.areaValue.value ||
      !form.cmProductsIds.value ||
      form.cmProductsIds.value.length === 0 ||
      !form.startDateStr.value ||
      !form.endDateStr.value ||
      formValue.value?.isFormDisabled.value ||
      validLabel.value
      ? true
      : false;
  });

  return {
    formValue,
    cardLabel,
    cardColor,
    isSubMenuOpen,
    doSubmit,
    validLabel,
    isSubmitButtonDisabled
  };
};
