import { ReachAnalysis2Api } from '@/api';
import {
  ReachAnalysisProject2SectionDisplaySettingForm,
  ReachAnalysisProject2SectionDisplaySettingRecord
} from '@/api/openapi';
import { httpCode } from '@/common/constant';
import { toast } from '@/components/ui/Toast';
import CmHeatMapSection from '@/pages/reachanalysis/CmHeatMapSection.vue';
import CpeComparisonSection from '@/pages/reachanalysis/CpeComparisonSection.vue';
import EffectiveReachSection from '@/pages/reachanalysis/EffectiveReachSection.vue';
import { ROUTE_NAMES } from '@/router';
import { Component, Ref, markRaw, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import useLoading from '@/composables/loading';

interface SectionSetting {
  projectId: number | null;
  sectionSettingTmpList: Ref<SectionSettingsType[]>;
  sectionSettingList: Ref<SectionSettingsType[]>;
  sectionSettingDafaultData: Ref<SectionSettingsType[]>;
  fetchSectionSettings: (projectId: number | null) => Promise<unknown>;
  initialSectionSettings: (projectId: number | null) => Promise<unknown>;
  saveSectionSettings: (projectId: number | null) => Promise<unknown>;
  isSaveSectionSettings: Ref<boolean>;
}

export type SectionSettingsType = {
  section: string;
  label?: string;
  isVisible: boolean;
  component?: Component;
};

export const useSectionSetting = (): SectionSetting => {
  const router = useRouter();
  const route = useRoute();
  const sectionSettingDafaultData: Ref<SectionSettingsType[]> = ref([]);
  const projectId = Number(route.params.projectId) ?? null;

  // 一時保持のセクション設定
  const sectionSettingTmpList: Ref<SectionSettingsType[]> = ref(
    sectionSettingDafaultData.value.map(v => ({ ...v }))
  );

  // 本セクション設定
  const sectionSettingList: Ref<SectionSettingsType[]> = ref(
    sectionSettingDafaultData.value.map(v => ({ ...v }))
  );

  // セクション設定取得
  const fetchSectionSettings = async (projectId: number | null) => {
    try {
      if (!projectId) return false;
      const res = await ReachAnalysis2Api.getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        // responseから取得したセクション設定を、一時保持のセクション設定に反映
        sectionSettingTmpList.value = res.data['displaySettingRecords'].map(
          (v: ReachAnalysisProject2SectionDisplaySettingRecord) => {
            return {
              section: v.section,
              isVisible: v.visible,
              label:
                v.section === 'CPE_COMPARISON'
                  ? 'CPE比較'
                  : v.section === 'EFFECTIVE_REACH'
                  ? 'エフェクティブリーチ'
                  : 'CM出稿ヒートマップ',
              component:
                v.section === 'CPE_COMPARISON'
                  ? markRaw(CpeComparisonSection)
                  : v.section === 'EFFECTIVE_REACH'
                  ? markRaw(EffectiveReachSection)
                  : markRaw(CmHeatMapSection)
            };
          }
        );
        // 一時保持のセクション設定を、本セクション設定に反映
        sectionSettingList.value = sectionSettingTmpList.value.map(v => {
          return {
            section: v.section,
            isVisible: v.isVisible,
            label: v.label,
            component: v.component
          };
        });
        return true;
      }
    } catch (e) {
      if (e.status === httpCode.forbidden) {
        await router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
        return false;
      }
      if (e.response.status === httpCode.notFound) {
        await router.push({
          name: ROUTE_NAMES.notFound,
          params: { backTop: 'true' },
          force: true
        });
        return false;
      }
      toast({
        title: '失敗',
        message: 'プロジェクトの取得に失敗しました',
        variant: 'error'
      });
      return false;
    }
  };

  // セクション設定初期値取得
  const initialSectionSettings = async (projectId: number | null) => {
    try {
      if (!projectId) return false;
      const res = await ReachAnalysis2Api.getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettingsDefaultValue(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        sectionSettingDafaultData.value = res.data['displaySettingRecords'].map(
          v => {
            return {
              section: v.section,
              isVisible: v.visible,
              label:
                v.section === 'CPE_COMPARISON'
                  ? 'CPE比較'
                  : v.section === 'EFFECTIVE_REACH'
                  ? 'エフェクティブリーチ'
                  : 'CM出稿ヒートマップ',
              component:
                v.section === 'CPE_COMPARISON'
                  ? markRaw(CpeComparisonSection)
                  : v.section === 'EFFECTIVE_REACH'
                  ? markRaw(EffectiveReachSection)
                  : markRaw(CmHeatMapSection)
            };
          }
        );
        return true;
      }
    } catch (e) {
      if (e.status === httpCode.forbidden) {
        await router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
        return false;
      }
      if (e.response.status === httpCode.notFound) {
        await router.push({
          name: ROUTE_NAMES.notFound,
          params: { backTop: 'true' },
          force: true
        });
        return false;
      }
      toast({
        title: '失敗',
        message: 'プロジェクトの取得に失敗しました',
        variant: 'error'
      });
      return false;
    }
  };

  // セクション設定更新
  const _saveSectionSettings = async (projectId: number | null) => {
    try {
      if (!projectId) return false;

      const createApiParameter = (
        sectionSettingTmpList
      ): ReachAnalysisProject2SectionDisplaySettingForm => {
        return {
          displaySettingRecords: sectionSettingTmpList.map(
            (v, index: number) => {
              return {
                section: v.section,
                displayOrder: index + 1,
                visible: v.isVisible
              };
            }
          )
        };
      };

      const form: ReachAnalysisProject2SectionDisplaySettingForm = createApiParameter(
        sectionSettingTmpList.value
      );

      const res = await ReachAnalysis2Api.putReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings(
        Number(projectId),
        form
      );
      if (200 <= res.status && res.status < 300) {
        // 一時保持のセクション設定を、本セクション設定に反映
        sectionSettingList.value = sectionSettingTmpList.value.map(v => {
          return {
            section: v.section,
            isVisible: v.isVisible,
            label: v.label,
            component: v.component
          };
        });
      }
    } catch (e) {
      if (e.status === httpCode.forbidden) {
        await router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
        return false;
      }
      if (e.response.status === httpCode.notFound) {
        await router.push({
          name: ROUTE_NAMES.notFound,
          params: { backTop: 'true' },
          force: true
        });
        return false;
      }
      toast({
        title: '失敗',
        message: 'プロジェクトの取得に失敗しました',
        variant: 'error'
      });
      return false;
    }
  };
  const [isSaveSectionSettings, saveSectionSettings] = useLoading(
    _saveSectionSettings
  );

  return {
    projectId,
    sectionSettingTmpList,
    sectionSettingList,
    sectionSettingDafaultData,
    fetchSectionSettings,
    initialSectionSettings,
    saveSectionSettings,
    isSaveSectionSettings
  };
};
