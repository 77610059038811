import { ReachAnalysis2Api } from '@/api';
import {
  ReachAnalysisProject2CardForm,
  ReachAnalysisProject2CardListCards,
  ReachAnalysisProject2SectionDisplay,
  ReachAnalysisProject2SectionDisplaySettingSetcionEnum,
  ReachAnalysisProject2StatusCalculateReasonEnum,
  ReachAnalysisProject2StatusStatusEnum
} from '@/api/openapi';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import { CardColor } from '@/composables/reachanalysis/settingCardCreateEditModal';
import { useReachAnalysisStore } from '@/store/reachAnalysis/';
import {
  CheckSelection,
  CheckSelectionList,
  GraphList,
  GraphType
} from '@/store/reachAnalysis/reachAnalysisCards';
import { storeToRefs } from 'pinia';
import { Ref, computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { handleError } from '@/common/handleError';

const convertToReachAnalysisProject2SectionDisplaySettingSetcionEnum = (
  val: string
): ReachAnalysisProject2SectionDisplaySettingSetcionEnum | undefined => {
  switch (val) {
    case ReachAnalysisProject2SectionDisplaySettingSetcionEnum.EffectiveReach:
      return ReachAnalysisProject2SectionDisplaySettingSetcionEnum.EffectiveReach;
    case ReachAnalysisProject2SectionDisplaySettingSetcionEnum.CpeComparison:
      return ReachAnalysisProject2SectionDisplaySettingSetcionEnum.CpeComparison;
    default:
      return undefined;
  }
};

type SettingCardsBoxReturnType = {
  projectId: string | undefined;
  fetchCards: (params: unknown) => Promise<void>;
  isFetchCards: Ref<boolean>;
  fetchDisplayGraphCard: (
    graphType: GraphType
  ) => Promise<ReachAnalysisProject2SectionDisplay | undefined>;
  isFetchDisplayGraphCard: Ref<boolean>;
  fetchDisplayHeatmapCard: (params: unknown) => Promise<number[] | undefined>;
  isFetchDisplayHeatmapCard: Ref<boolean>;
  isUpdateDisplayGraphCard: Ref<boolean>;
  isUpdateDisplayHeatMapCard: Ref<boolean>;
  isCpeComparison: Ref<boolean>;
  isFetchEffectiveReach: Ref<boolean>;
  isFetchCmHeatMap: Ref<boolean>;
  createCard: (cardForm: ReachAnalysisProject2CardForm) => Promise<void>;
  isCreatingCard: Ref<boolean>;
  sortCard: (params: unknown) => Promise<void>;
  isSortingCard: Ref<boolean>;
  isSameCheck: Ref<boolean>;
  isCheckMode: Ref<boolean>;
  isShowSameButton: Ref<boolean>;
  isSameButtonDisabled: Ref<boolean>;
  tmpCheckModel: Ref<number[]>;
  submitCheck: () => Promise<void>;
  cancelCheck: () => void;
  isOpenSettingCardCreateEditModal: Ref<boolean>;
  setCards: (isFetchCardsOnly?: boolean) => void;
  setGraph: () => void;
  getUnusedColor: () => string;
};

const errToast = (msg: string) => {
  toast({
    title: '失敗',
    message: msg,
    variant: 'error'
  });
};

export const useSettingCardsBox = (
  getProjectStatus: () => void
): SettingCardsBoxReturnType => {
  const route = useRoute();
  const projectId = route.params.projectId?.toString();
  const store = useReachAnalysisStore();
  const { checkModeSelection, sectionData } = storeToRefs(store);
  const isOpenSettingCardCreateEditModal = ref(false);

  const _fetchCards = async () => {
    try {
      if (!projectId) return;

      const res = await ReachAnalysis2Api.getReachAnalysisProjects2ReachAnalysisProject2IdCards(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        const cards: ReachAnalysisProject2CardListCards[] = res.data.cards;
        store.setCards(cards);
      } else {
        errToast('カード一覧情報取得に失敗しました');
      }
    } catch (e) {
      errToast('カード一覧情報取得に失敗しました');
    }
  };
  const [isFetchCards, fetchCards] = useLoading(_fetchCards);

  const _fetchDisplayGraphCard = async (graphType: GraphType) => {
    try {
      if (!projectId) return undefined;

      const res = await ReachAnalysis2Api.getReachAnalysisProjects2ReachAnalysisProject2IdDisplayCards(
        Number(projectId),
        graphType
      );
      if (200 <= res.status && res.status < 300) {
        return res.data;
      } else {
        errToast('グラフの比較対象カード設定取得に失敗しました');
        return undefined;
      }
    } catch (e) {
      errToast('グラフの比較対象カード設定取得に失敗しました');
      return undefined;
    }
  };
  const [isFetchDisplayGraphCard, fetchDisplayGraphCard] = useLoading(
    _fetchDisplayGraphCard
  );

  const _fetchDisplayHeatmapCard = async () => {
    try {
      if (!projectId) return undefined;

      const res = await ReachAnalysis2Api.getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        return res.data.displayCardIds;
      } else {
        errToast('CMヒートマップの表示対象カード情報取得に失敗しました');
        return undefined;
      }
    } catch (e) {
      errToast('CMヒートマップの表示対象カード情報取得に失敗しました');
      return undefined;
    }
  };
  const [isFetchDisplayHeatmapCard, fetchDisplayHeatmapCard] = useLoading(
    _fetchDisplayHeatmapCard
  );

  const _updateDisplayGraphCard = async () => {
    try {
      if (!checkModeSelection.value) return false;

      const graphType = convertToReachAnalysisProject2SectionDisplaySettingSetcionEnum(
        checkModeSelection.value
      );
      const mainCardId =
        sectionData.value[checkModeSelection.value].dispCard.mainCardId;
      const subCardIds = tmpCheckModel.value.filter(v => v !== mainCardId);

      if (!projectId || !graphType) return false;

      const displaySetting = {
        setcion: graphType,
        subCardIds: subCardIds
      };

      const res = await ReachAnalysis2Api.putReachAnalysisProjects2ReachAnalysisProject2IdDisplay(
        Number(projectId),
        displaySetting
      );
      if (200 <= res.status && res.status < 300) {
        store.setSubCardIds(checkModeSelection.value, subCardIds);
        return true;
      } else {
        errToast('グラフの比較対象カード設定更新に失敗しました');
        return false;
      }
    } catch (e) {
      errToast('グラフの比較対象カード設定更新に失敗しました');
      return false;
    }
  };
  const [isUpdateDisplayGraphCard, updateDisplayGraphCard] = useLoading(
    _updateDisplayGraphCard
  );

  const _updateDisplayHeatMapCard = async (
    selection: CheckSelection | undefined = undefined
  ) => {
    try {
      const selectionValue = selection ?? checkModeSelection.value;
      if (!selectionValue || !projectId) return;

      if (selection) {
        tmpCheckModel.value = sectionData.value.CM_HEATMAP.dispCard.subCardIds;
      }

      if (isSameCheck.value) {
        tmpCheckModel.value.push(tmpCheckModel.value[0]);
      } else if (tmpCheckModel.value.length > 1) {
        // カードのdisplayOrderを元にtmpCheckModelをソート
        const tmp = store.cards?.filter(v =>
          tmpCheckModel.value.includes(v.basicInfo.cardId)
        );
        if (tmp) {
          tmpCheckModel.value = tmp
            .sort((a, b) => a.basicInfo.displayOrder - b.basicInfo.displayOrder)
            .map(v => v.basicInfo.cardId);
        }
      }
      const heatmapDisplay = {
        displayCardIds: tmpCheckModel.value
      };

      const res = await ReachAnalysis2Api.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards(
        Number(projectId),
        heatmapDisplay
      );
      if (200 <= res.status && res.status < 300) {
        store.setSubCardIds(selectionValue, tmpCheckModel.value);
      } else {
        errToast('CMヒートマップの表示対象カード情報更新に失敗しました');
        return undefined;
      }
    } catch (e) {
      errToast('CMヒートマップの表示対象カード情報更新に失敗しました');
      return undefined;
    } finally {
      if (selection) {
        tmpCheckModel.value = [];
      }
    }
  };
  const [isUpdateDisplayHeatMapCard, updateDisplayHeatMapCard] = useLoading(
    _updateDisplayHeatMapCard
  );

  const _fetchCpeComparison = async () => {
    store.setIsCpeComparisonLoading(true);
    try {
      if (!projectId) return false;

      const res = await ReachAnalysis2Api.getReachAnalysisProjects2ReachAnalysisProject2IdCpeComparison(
        Number(projectId)
      );

      if (200 <= res.status && res.status < 300) {
        store.setCpeComparisonList(res.data.cards);
        return true;
      } else {
        errToast('CPE比較データ取得に失敗しました');
        return false;
      }
    } catch (e) {
      errToast('CPE比較データ取得に失敗しました');
      return false;
    } finally {
      store.setIsCpeComparisonLoading(false);
    }
  };
  const [isCpeComparison, fetchCpeComparison] = useLoading(_fetchCpeComparison);

  const _fetchEffectiveReach = async () => {
    store.setIsEReachGraphLoading(true);
    try {
      if (!projectId) return false;

      const res = await ReachAnalysis2Api.getReachAnalysisProjects2ReachAnalysisProject2IdEffectiveReach(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        store.setEffectiveReachGraph(res.data);
        return true;
      } else {
        errToast('プロジェクトのグラフデータ取得に失敗しました');
        return false;
      }
    } catch (e) {
      errToast('プロジェクトのグラフデータ取得に失敗しました');
      return false;
    } finally {
      store.setIsEReachGraphLoading(false);
    }
  };
  const [isFetchEffectiveReach, fetchEffectiveReach] = useLoading(
    _fetchEffectiveReach
  );

  const _fetchCmHeatMap = async () => {
    store.setIsCmHeatmapLoading(true);
    try {
      if (!projectId) return;

      const res = await ReachAnalysis2Api.getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmap(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        store.setCmHeatmapGraph(res.data);
      } else {
        errToast('CM出稿ヒートマップのデータ取得に失敗しました');
      }
    } catch (e) {
      errToast('CM出稿ヒートマップのデータ取得に失敗しました');
    } finally {
      store.setIsCmHeatmapLoading(false);
    }
  };
  const [isFetchCmHeatMap, fetchCmHeatMap] = useLoading(_fetchCmHeatMap);

  const _createCard = async (cardForm: ReachAnalysisProject2CardForm) => {
    try {
      const res = await ReachAnalysis2Api.postReachAnalysisProjects2ReachAnalysisProject2IdCards(
        Number(projectId),
        cardForm
      );
      if (200 <= res.status && res.status < 300) {
        fetchCards('');
        getProjectStatus();
      } else {
        errToast('カードの追加に失敗しました');
      }
    } catch (e) {
      handleError(e);
      errToast('カードの追加に失敗しました');
    } finally {
      isOpenSettingCardCreateEditModal.value = false;
    }
  };
  const [isCreatingCard, createCard] = useLoading(_createCard);

  const _sortCards = async () => {
    if (!store.cards) return;

    const cardOrders = store.cards.map((v, i) => ({
      cardId: v.basicInfo.cardId,
      displayOrder: i + 1
    }));
    const reachAnalysisProject2CardOrder = {
      cardOrders: cardOrders
    };

    try {
      const res = await ReachAnalysis2Api.putReachAnalysisProjects2ReachAnalysisProject2IdCardsOrder(
        Number(projectId),
        reachAnalysisProject2CardOrder
      );
      if (200 <= res.status && res.status < 300) {
        await fetchCards('');
        setDisplayGraphCardAll();
        await updateDisplayHeatMapCard(CheckSelectionList.cmHeatMap);
        setDisplayHeatmapCard();
      } else {
        errToast('カードの並び順更新に失敗しました');
      }
    } catch (e) {
      handleError(e);
      errToast('カードの並び順更新に失敗しました');
    } finally {
      isOpenSettingCardCreateEditModal.value = false;
    }
  };
  const [isSortingCard, sortCard] = useLoading(_sortCards);

  // 表示カードチェックボックス操作時の処理関連 /////////////////////////////////
  const isCheckMode = computed(() => {
    return !!checkModeSelection.value;
  });

  const isShowSameButton = computed(() => {
    return (
      isCheckMode.value &&
      checkModeSelection.value === CheckSelectionList.cmHeatMap
    );
  });

  const isSameButtonDisabled = computed(() => {
    if (tmpCheckModel.value.length >= store.dispCardMax) return true;
    return false;
  });

  const isSameCheck = ref(false);

  const tmpCheckModel: Ref<number[]> = ref([]);

  const submitCheck = async () => {
    const GraphListValues: string[] = Object.values(GraphList);
    if (
      checkModeSelection.value &&
      GraphListValues.includes(checkModeSelection.value)
    ) {
      // グラフの表示カード更新
      await updateDisplayGraphCard('');
    } else if (checkModeSelection.value) {
      // ヒートマップの表示カード更新
      await updateDisplayHeatMapCard(undefined);
    }
    store.setCheckSelection(CheckSelectionList.none);
  };

  const cancelCheck = () => {
    store.setCheckSelection(CheckSelectionList.none);
  };

  watch(checkModeSelection, () => {
    tmpCheckModel.value = [];
    if (checkModeSelection.value) {
      const mainId =
        sectionData.value[checkModeSelection.value].dispCard.mainCardId;
      if (mainId) tmpCheckModel.value.push(mainId);
      sectionData.value[checkModeSelection.value].dispCard.subCardIds.map(v => {
        tmpCheckModel.value.push(v);
      });
      // ヒートマップが同一カードで比較状態となっている場合
      if (
        checkModeSelection.value === CheckSelectionList.cmHeatMap &&
        tmpCheckModel.value.length > 1 &&
        tmpCheckModel.value[0] === tmpCheckModel.value[1]
      ) {
        isSameCheck.value = true;
        tmpCheckModel.value = [tmpCheckModel.value[0]];
      }
    }
  });
  /////////////////////////////////////////////////////////////////////////

  const setCards = async () => {
    fetchCards('');
    setDisplayGraphCardAll();
    setDisplayHeatmapCard();
  };

  // ヒートマップを除く全てのセクションの表示カード取得
  const setDisplayGraphCardAll = () => {
    Object.values(GraphList).map(async graphType => {
      setDisplayGraphCardIndividual(graphType);
    });
  };
  // セクションの表示カード取得
  const setDisplayGraphCardIndividual = async (graphType: GraphType) => {
    const dispCard = await fetchDisplayGraphCard(graphType);
    if (dispCard) store.setDispCard(graphType, dispCard);
  };

  // ヒートマップの表示カード取得
  const setDisplayHeatmapCard = async () => {
    const dispHeatmapCard = await fetchDisplayHeatmapCard('');
    if (dispHeatmapCard) {
      store.setSubCardIds(CheckSelectionList.cmHeatMap, dispHeatmapCard);
      if (dispHeatmapCard.length > 1) {
        isSameCheck.value = dispHeatmapCard[1] === dispHeatmapCard[2];
      } else {
        isSameCheck.value = false;
      }
    }
  };

  const setDisplayCard = (section: CheckSelection) => {
    if (!section) return;
    if (section === CheckSelectionList.cmHeatMap) {
      setDisplayHeatmapCard();
    } else {
      setDisplayGraphCardIndividual(section);
    }
  };

  const fetchSectionData = (section: CheckSelection) => {
    switch (section) {
      case CheckSelectionList.cpeComparison:
        fetchCpeComparison('');
        break;
      case CheckSelectionList.effectiveReach:
        fetchEffectiveReach('');
        break;
      case CheckSelectionList.cmHeatMap:
        fetchCmHeatMap('');
        break;
      default:
        break;
    }
  };

  // セクション毎の計算中ステータスのカードIDリスト
  const sectionsAggregatingIds = computed(() => {
    const list: { [x: string]: number[] | undefined } = {};
    Object.values(CheckSelectionList).forEach(section => {
      if (!section) return undefined;
      list[section] = sectionData.value[section].aggregatingIds;
    });
    return list;
  });

  watch(
    () => sectionsAggregatingIds.value,
    (newSAIds, oldSAIds) => {
      // カード追加による集計かどうか
      const isCreation =
        store.projectStatus?.status ===
          ReachAnalysisProject2StatusStatusEnum.Calculating &&
        store.projectStatus?.calculateReason ===
          ReachAnalysisProject2StatusCalculateReasonEnum.CreateCard;

      Object.values(CheckSelectionList).forEach(section => {
        if (!section) return;
        const oldIds = oldSAIds[section];
        const newIds = newSAIds[section];
        if (oldIds === newIds || (isCreation && newIds && newIds.length > 0))
          return;
        setDisplayCard(section);
        fetchSectionData(section);
      });
    },
    { deep: true }
  );

  const setGraph = async () => {
    Object.values(CheckSelectionList).forEach(section => {
      if (!section) return;
      const ids = sectionsAggregatingIds.value[section];
      if (ids) fetchSectionData(section);
    });
  };

  watch(
    () => store.isSetCardsAndStatus,
    () => {
      if (store.isSetCardsAndStatus) {
        try {
          setCards();
          getProjectStatus();
        } finally {
          () => {
            store.setIsSetCardsAndStatus(false);
          };
        }
      }
    }
  );

  const getUnusedColor = () => {
    const usedColors = store.cards?.map(v => v.basicInfo.color as string) ?? [];
    const unusedColors = Object.values(CardColor).filter(
      v => !usedColors.includes(v)
    );
    return unusedColors.length > 0 ? unusedColors[0] : CardColor.Blue;
  };

  const init = async () => {
    store.setCheckSelection(CheckSelectionList.none);
    await setCards();

    if (
      store.projectStatus?.status ===
      ReachAnalysisProject2StatusStatusEnum.Calculating
    ) {
      switch (store.projectStatus?.calculateReason) {
        case ReachAnalysisProject2StatusCalculateReasonEnum.CreateProject:
          return;
        case ReachAnalysisProject2StatusCalculateReasonEnum.CreateCard:
          Object.values(CheckSelectionList).forEach(section => {
            if (!section) return;
            const referableIds = sectionData.value[section].referableIds;
            if (referableIds && referableIds.length > 0)
              fetchSectionData(section);
          });
          return;
        default:
          break;
      }
    }

    setGraph();
  };

  init();

  return {
    projectId,
    fetchCards,
    isFetchCards,
    fetchDisplayGraphCard,
    isFetchDisplayGraphCard,
    fetchDisplayHeatmapCard,
    isFetchDisplayHeatmapCard,
    isUpdateDisplayGraphCard,
    isUpdateDisplayHeatMapCard,
    isCpeComparison,
    isFetchEffectiveReach,
    isFetchCmHeatMap,
    createCard,
    isCreatingCard,
    sortCard,
    isSortingCard,
    isSameCheck,
    isCheckMode,
    isShowSameButton,
    isSameButtonDisabled,
    tmpCheckModel,
    submitCheck,
    cancelCheck,
    isOpenSettingCardCreateEditModal,
    setCards,
    setGraph,
    getUnusedColor
  };
};
