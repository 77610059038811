import { ReachAnalysisProject2ProductsGrpGraph } from '@/api/openapi';
import { DATE_FORMAT } from '@/common/format';
import { CHART_OPTIONS_BASE } from '@/composables/reachanalysis/baseChart';
import { format } from 'date-fns';
import { Ref, computed, ref } from 'vue';

type DailyGRPReturnTypes = {
  graphTitle: string;
  graphData: Ref<(string | number)[][]>;
  dragMin: Ref<number>;
  dragMax: Ref<number>;
  setPeriod: (newPoint: number, oldPoint: number) => void;
  periodData: Ref<{ y: number; x: number }[]>;
  startPoint: Ref<number>;
  endPoint: Ref<number>;
  periodYMax: number;
};

export const DAILY_GRP_CHART = {
  id: 'grp',
  name: 'grp',
  color: '#B5C7EF',
  type: 'column',
  legendIndex: 1,
  showInLegend: false,
  dragDrop: {
    draggableX: false,
    draggableY: false
  },
  yAxis: 0,
  index: 2,
  zIndex: 2,
  states: {
    inactive: {
      opacity: 1
    }
  }
};

export const PERIOD_CHART = {
  id: 'period',
  name: 'period',
  color: 'rgb(255 245 223 / 50%)',
  type: 'area',
  showInLegend: false,
  yAxis: 1,
  index: 1,
  zIndex: 1,
  states: {
    inactive: {
      opacity: 1
    }
  }
};

export const GRP_CHART_OPTIONS = {
  ...CHART_OPTIONS_BASE,
  plotOptions: {
    ...CHART_OPTIONS_BASE.plotOptions,
    area: {
      stacking: true,
      lineWidth: 0,
      shadow: false,
      marker: {
        enabled: false
      }
    },
    line: {
      zIndex: 3
    }
  }
};

export const useDailyGRP = (
  rawGrpData: Ref<ReachAnalysisProject2ProductsGrpGraph[]>,
  startValue: Ref<Date | undefined | null>,
  endValue: Ref<Date | undefined | null>,
  periodDatePickerKey: Ref<number>,
  updatePeriod: () => void
): DailyGRPReturnTypes => {
  const graphTitle = 'GRP';
  const periodYMax = 20;

  const graphData = computed(() => {
    return rawGrpData.value.map(v => {
      return [v.date, v.grp];
    });
  });

  const dragMin = ref(0);
  const dragMax = ref(graphData.value.length - 1);
  const startPoint = computed(() => {
    const start = format(startValue.value ?? new Date(), DATE_FORMAT);
    const fromBase = graphData.value.findIndex(v => v[0] === start);
    return fromBase < 0 ? graphData.value.length - 0.5 : fromBase - 0.5;
  });
  const endPoint = computed(() => {
    const end = format(endValue.value ?? new Date(), DATE_FORMAT);
    const toBase = graphData.value.findIndex(v => v[0] === end);
    return toBase < 0 ? graphData.value.length - 0.5 : toBase + 0.5;
  });

  const periodData = computed(() => {
    return [
      {
        y: periodYMax,
        x: startPoint.value
      },
      {
        y: periodYMax,
        x: endPoint.value
      }
    ];
  });

  const setPeriod = (newPoint: number, oldPoint: number) => {
    updatePeriod();
    if (startPoint.value >= newPoint) {
      if (oldPoint === endPoint.value) {
        const setDay = graphData.value[startPoint.value][0];
        endValue.value = new Date(setDay);
      }
      const setDay = graphData.value[Math.round(newPoint)][0];
      startValue.value = new Date(setDay);
      periodDatePickerKey.value += 1;
      return;
    }
    if (endPoint.value <= newPoint) {
      if (oldPoint === startPoint.value) {
        const setDay = graphData.value[endPoint.value][0];
        startValue.value = new Date(setDay);
      }
      const setDay = graphData.value[Math.floor(newPoint)][0];
      endValue.value = new Date(setDay);
      periodDatePickerKey.value += 1;
      return;
    }
    if (oldPoint === startPoint.value) {
      const setDay = graphData.value[Math.round(newPoint)][0];
      startValue.value = new Date(setDay);
      periodDatePickerKey.value += 1;
      return;
    }
    if (oldPoint === endPoint.value) {
      const setDay = graphData.value[Math.floor(newPoint)][0];
      endValue.value = new Date(setDay);
      periodDatePickerKey.value += 1;
      return;
    }
  };

  return {
    graphTitle,
    graphData,
    dragMin,
    dragMax,
    setPeriod,
    periodData,
    startPoint,
    endPoint,
    periodYMax
  };
};
