import { ReachAnalysis2Api } from '@/api';
import {
  ReachAnalysisProject2CardDetailCostTypeEnum,
  ReachAnalysisProject2CardForm,
  ReachAnalysisProject2CardListCards,
  ReachAnalysisProject2CpeComparisonCards,
  ReachAnalysisProject2StatusCalculateReasonEnum
} from '@/api/openapi';
import { DATE_FORMAT } from '@/common/format';
import { resolveNumberWithUnit, round, roundNumber } from '@/common/formatter';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import {
  convertToCSV,
  downloadCsv,
  getCSVHeader
} from '@/composables/reachanalysis/csv';
import { useReachAnalysisStore } from '@/store/reachAnalysis';
import { format, getHours, isAfter, subDays } from 'date-fns';
import { Ref, computed, ref } from 'vue';
import { useRoute } from 'vue-router';

interface ExReachAnalysisProject2CpeComparisonCards
  extends ReachAnalysisProject2CpeComparisonCards {
  campaign: string | undefined;
  cardColor: string | undefined;
  isMain: boolean;
  cpeLabel: string;
  isProgressCpe: boolean;
  percent: number | undefined;
  cpvLabel: string;
  isProgressCpv: boolean;
  isProgressPlacementCost: boolean;
  isProgressPerCost: boolean;
  isSetCost: boolean;
  imageOnlyId: string;
  order: number;
  isCardLoading: boolean;
}

type CpeComparisonSectionReturnTypes = {
  fileName: string;
  isOpenSettingCardCreateEditModal: Ref<boolean>;
  cardData: Ref<ReachAnalysisProject2CardListCards | undefined>;
  mainCard: Ref<ReachAnalysisProject2CardListCards | undefined>;
  openSettingCardCreateEditModal: (cardId: number) => void;
  isUpdatingCard: Ref<boolean>;
  updateCard: (cardForm: ReachAnalysisProject2CardForm) => void;
  cpeComparisonList: Ref<
    ExReachAnalysisProject2CpeComparisonCards[] | undefined
  >;
  isHasProgress: Ref<boolean>;
  placementCostNullImage: string;
  imageOnlyIdList: Ref<string[] | undefined>;
  isLoading: Ref<boolean>;
  exportCsv: () => void;
};

const placementCostNullImage = '#PlacementCostNullImage';

const errorToast = () => {
  toast({
    title: '失敗',
    message: 'カードの更新に失敗しました',
    variant: 'error'
  });
};

const getFinalReportDay = () => {
  const today = new Date();
  const hour = getHours(today);
  let finalReportDay =
    0 <= hour && hour < 5 ? subDays(today, 4) : subDays(today, 3);
  // ローカル時刻からUTCに変更
  finalReportDay = new Date(format(finalReportDay, DATE_FORMAT));
  return finalReportDay;
};

export const useCpeComparisonSection = (
  getProjectStatus: () => void
): CpeComparisonSectionReturnTypes => {
  const store = useReachAnalysisStore();
  const route = useRoute();
  const projectId = route.params.projectId?.toString();
  const isOpenSettingCardCreateEditModal = ref(false);
  const cardData: Ref<ReachAnalysisProject2CardListCards | undefined> = ref();
  const mainCard = computed(() => {
    return store.mainCard();
  });
  const isMainCardSetCost = computed(() => {
    return (
      !!mainCard.value?.basicInfo.placementCost ||
      !!mainCard.value?.basicInfo.perCost
    );
  });
  const errorCards = computed(() => {
    let errorList = store.errorCardList ?? [];
    errorList = errorList.concat(store.PeriodErrorCardList ?? []);
    return errorList;
  });
  // 確報日
  const finalReportDay = getFinalReportDay();
  // 表示カードリスト
  const dispCardList = computed(() => {
    const list = store.sectionData.CPE_COMPARISON.dispCard.subCardIds.slice();
    if (store.sectionData.CPE_COMPARISON.dispCard.mainCardId)
      list.push(store.sectionData.CPE_COMPARISON.dispCard.mainCardId);
    return list;
  });
  // ローディングカードリスト
  const loadingCardList = computed(() => {
    return store.sectionData.CPE_COMPARISON.aggregatingIds;
  });

  // メインカードのCPE比較データ
  const mainCpeComparison = computed(() => {
    const mainCardId = mainCard.value?.basicInfo.cardId;
    const cpeComparison = store.cpeComparisonList?.find(
      v => v.cardId === mainCardId
    );
    const isProgress = getIsProgress(mainCardId ?? 0);
    let placementCost = cpeComparison?.placementCost;
    if (mainCard.value?.basicInfo.perCost && !cpeComparison?.placementCost)
      placementCost = 0;
    if (
      !!mainCard.value?.basicInfo.placementCost &&
      !cpeComparison?.grp &&
      isProgress
    )
      placementCost = undefined;

    let perCost = cpeComparison?.perCost;
    if (!!mainCard.value?.basicInfo.perCost && !cpeComparison?.grp) {
      perCost = mainCard.value?.basicInfo.perCost;
    }

    return {
      ...cpeComparison,
      placementCost,
      perCost
    };
  });

  const getIsReferMainPerCost = (card: ReachAnalysisProject2CardListCards) => {
    return (
      card.basicInfo.costType ===
      ReachAnalysisProject2CardDetailCostTypeEnum.ReferMainPerCost
    );
  };

  const getIsProgress = (cardId: number) => {
    const cardData = store.card(cardId);
    if (!cardData) return false;
    const endDate = new Date(cardData?.basicInfo.endDate);
    return isAfter(endDate, finalReportDay);
  };

  const getIsProgressCpe = (cardId: number, cpe: number | undefined) => {
    const isProgress = getIsProgress(cardId);
    const isSetCost = getIsSetCost(cardId);
    const cpeLabel = getCpeLabel(cpe);
    return isProgress && isSetCost && !!cpeLabel;
  };

  const getIsProgressCpv = (cardId: number, cpv: number | undefined) => {
    const isProgress = getIsProgress(cardId);
    const isSetCost = getIsSetCost(cardId);
    const cpvLabel = getCpv(cpv);
    return isProgress && isSetCost && !!cpvLabel;
  };

  const getIsProgressPlacementCost = (
    cardId: number,
    placementCost: number | undefined
  ) => {
    const isProgress = getIsProgress(cardId);
    const isSetCost = getIsSetCost(cardId);
    const placementCostLabel = getLabelWithUnit(placementCost);
    return (
      isProgress &&
      isSetCost &&
      (!!placementCost || placementCost === 0 || placementCostLabel === 'ー')
    );
  };

  const getIsProgressPerCost = (
    cardId: number,
    perCost: number | undefined,
    grp: number | undefined
  ) => {
    const isProgress = getIsProgress(cardId);
    const isSetCost = getIsSetCost(cardId);
    const isInputPerCost = getIsInputPerCost(cardId);
    const perCostLabel = getLabelWithUnit(getPerCost(cardId, perCost, grp));
    const cardData = store.card(cardId);
    const mainCardId = mainCard.value?.basicInfo.cardId;
    if (!cardData || !mainCardId) return false;
    const isReferMainPerCost = getIsReferMainPerCost(cardData);
    const mainCardIsProgress = getIsProgress(mainCard.value?.basicInfo.cardId);
    if (isProgress && isReferMainPerCost) {
      if (!mainCardIsProgress) return false;
      if (mainCardIsProgress && !!mainCard.value?.basicInfo.perCost)
        return false;
    }
    return (
      isProgress &&
      isSetCost &&
      ((!isInputPerCost && (!!perCostLabel || perCostLabel === '0')) ||
        perCostLabel === 'ー')
    );
  };

  const getIsInputPerCost = (cardId: number) => {
    const cardData = store.card(cardId);
    if (!cardData) return false;
    return !!cardData?.basicInfo.perCost;
  };

  const getPercent = (cardId: number, val: number | undefined) => {
    const cpeList = store.cpeComparisonList
      ?.filter(
        v =>
          dispCardList.value.includes(v.cardId) &&
          !loadingCardList.value?.includes(v.cardId)
      )
      .map(v => {
        const cpe = v.cpe;
        return cpe || cpe === 0 ? round(cpe, 2) : undefined;
      })
      .filter((v): v is NonNullable<typeof v> => v !== undefined && v !== null);
    if (getCpeLabel(val) === 'ー') return undefined;
    if (val === undefined || val === null || !cpeList) return undefined;
    if (cpeList.every(v => v === cpeList[0])) return 50;
    const max = Math.max(...cpeList);
    const min = Math.min(...cpeList);
    const oneHundredPercentVal = max - min;
    return oneHundredPercentVal === 0
      ? 50
      : ((round(val, 2) - min) / oneHundredPercentVal) * 100;
  };

  const getCpeLabel = (val: number | undefined) => {
    if (val || val === 0) return roundNumber(val, 2);

    return 'ー';
  };

  const getCpv = (val: number | undefined) => {
    if (val || val === 0) return roundNumber(val, 3);

    return 'ー';
  };

  const getPlacementCost = (
    cardId: number,
    placementCost: number | undefined,
    grp: number | undefined
  ) => {
    const cardData = store.card(cardId);
    if (!cardData) return undefined;
    const isReferMainPerCost = getIsReferMainPerCost(cardData);
    if (isReferMainPerCost) {
      if (!!mainCpeComparison.value?.perCost && !placementCost) return 0;
      if (!mainCpeComparison.value?.perCost) return undefined;
    }
    if (cardData.basicInfo.perCost && !placementCost) return 0;
    const isProgress = getIsProgress(cardId);
    if (!!cardData.basicInfo.placementCost && !grp && isProgress)
      return undefined;
    return placementCost;
  };

  const getPerCost = (
    cardId: number,
    perCost: number | undefined,
    grp: number | undefined
  ) => {
    const cardData = store.card(cardId);
    if (!cardData) return undefined;
    const isReferMainPerCost = getIsReferMainPerCost(cardData);
    if (isReferMainPerCost) {
      if (mainCpeComparison.value?.perCost) {
        return mainCpeComparison.value?.perCost;
      } else {
        return undefined;
      }
    }
    if (!!cardData.basicInfo.perCost && !grp) return cardData.basicInfo.perCost;
    return perCost;
  };

  const getIsSetCost = (cardId: number) => {
    const cardData = store.card(cardId);
    if (!cardData) return false;
    const isReferMainPerCost = getIsReferMainPerCost(cardData);
    if (
      cardData.basicInfo.placementCost ||
      cardData.basicInfo.placementCost === 0 ||
      cardData.basicInfo.perCost ||
      cardData.basicInfo.perCost === 0 ||
      (isReferMainPerCost && isMainCardSetCost.value)
    ) {
      return true;
    }

    return false;
  };

  // CPE比較一覧
  const cpeComparisonList = computed(() => {
    const list = store.cpeComparisonList
      ?.filter(v => !errorCards.value?.includes(v.cardId))
      .map(v => {
        const cardData = store.card(v.cardId);
        return {
          ...v,
          campaign: cardData?.basicInfo.label,
          cardColor: cardData?.basicInfo.color,
          isMain: cardData?.basicInfo.isMain ?? false,
          cpeLabel: getCpeLabel(v.cpe),
          isProgressCpe: getIsProgressCpe(v.cardId, v.cpe),
          percent: getPercent(v.cardId, v.cpe),
          cpvLabel: getCpv(v.cpv),
          isProgressCpv: getIsProgressCpv(v.cardId, v.cpv),
          placementCost: getPlacementCost(v.cardId, v.placementCost, v.grp),
          isProgressPlacementCost: getIsProgressPlacementCost(
            v.cardId,
            v.placementCost
          ),
          perCost: getPerCost(v.cardId, v.perCost, v.grp),
          isProgressPerCost: getIsProgressPerCost(v.cardId, v.perCost, v.grp),
          isSetCost: getIsSetCost(v.cardId),
          imageOnlyId: placementCostNullImage + v.cardId,
          order: cardData?.basicInfo.displayOrder ?? -1,
          isCardLoading: loadingCardList.value?.includes(v.cardId) ?? false
        };
      });

    list?.sort((a, b) => a.order - b.order);
    return list?.filter(v => dispCardList.value.includes(v.cardId));
  });

  const isHasProgress = computed(() => {
    return (
      cpeComparisonList.value?.some(
        v =>
          (v.isProgressCpe ||
            v.isProgressCpv ||
            v.isProgressPlacementCost ||
            v.isProgressPerCost) &&
          !loadingCardList.value?.includes(v.cardId)
      ) || false
    );
  });

  const imageOnlyIdList = computed(() => {
    // 出稿費用がnullの場合に画像にのみハイフン表示するためのIDリスト
    return cpeComparisonList.value
      ?.filter(v => v.placementCost == null)
      .map(v => v.imageOnlyId);
  });

  const openSettingCardCreateEditModal = (cardId: number) => {
    cardData.value = store.card(cardId);
    isOpenSettingCardCreateEditModal.value = true;
  };

  const isLoading = computed(() => {
    if (
      store.projectStatus?.calculateReason ===
        ReachAnalysisProject2StatusCalculateReasonEnum.CreateProject &&
      store.sectionData.CPE_COMPARISON.aggregatingIds &&
      store.sectionData.CPE_COMPARISON.aggregatingIds.length > 0
    ) {
      return true;
    }
    if (!cpeComparisonList.value) {
      return true;
    }

    return false;
  });

  const _updateCard = async (cardForm: ReachAnalysisProject2CardForm) => {
    try {
      const cardId = cardData.value?.basicInfo.cardId;
      if (!cardId) return;
      const res = await ReachAnalysis2Api.putReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(
        Number(projectId),
        cardId,
        cardForm
      );
      if (200 <= res.status && res.status < 300) {
        store.setIsSetCardsAndStatus(true);
        getProjectStatus();
      } else {
        errorToast();
      }
    } catch (e) {
      handleError(e);
      errorToast();
    } finally {
      isOpenSettingCardCreateEditModal.value = false;
      cardData.value = undefined;
    }
  };
  const [isUpdatingCard, updateCard] = useLoading(_updateCard);

  const today = format(new Date(), DATE_FORMAT);
  const fileName = `TVAL_CPECOMP_${projectId}_${today}`;

  const exportCsv = () => {
    const sep = ',';
    const lineFeed = '\n';
    const dispGrapIds = cpeComparisonList.value?.map(v => v.cardId);
    const dispCards = store.extendsCards?.filter(v =>
      dispGrapIds?.includes(v.basicInfo.cardId)
    );
    const header = getCSVHeader('CPE比較', dispCards ?? []);

    const titles = [
      'キャンペーン',
      'CPE（円）',
      'CPV（円）',
      'Eリーチ人数（人）',
      '視聴回数（回）',
      '出稿費用（円）',
      '個人GRP',
      'パーコスト（円）'
    ].join(sep);

    const body: string[][] = [[]];
    if (cpeComparisonList.value) {
      cpeComparisonList.value.forEach(v => {
        const line = [
          v.campaign ? convertToCSV(v.campaign) : '─',
          v.cpe?.toString() || '─',
          v.cpv?.toString() || '─',
          round(v.effectiveReachNumberOfPeople, 1)?.toString() || '─',
          round(v.targetNumberOfCmViewing, 1)?.toString() || '─',
          v.placementCost?.toString() || '─',
          v.grp?.toString() || '─',
          v.perCost?.toString() || '─'
        ];
        body.push(line);
      });
    }

    const csvData = header.join('') + titles + body.join(lineFeed);
    downloadCsv(fileName + '.csv', csvData);
  };

  return {
    fileName,
    isOpenSettingCardCreateEditModal,
    cardData,
    mainCard,
    cpeComparisonList,
    isHasProgress,
    placementCostNullImage: placementCostNullImage,
    imageOnlyIdList: imageOnlyIdList,
    openSettingCardCreateEditModal,
    isUpdatingCard,
    updateCard,
    isLoading,
    exportCsv
  };
};

const billion = 1_000_000_000;
const one_hundred_million = 100_000_000;
const one_million = 10_000_000;
const ten_thousand = 10_000;
export const getLabelWithUnit = (val: number | undefined | null): string => {
  if (!val && val !== 0) return 'ー';
  if (val >= billion) {
    return resolveNumberWithUnit(val, 2, '');
  } else if (val >= one_hundred_million) {
    return resolveNumberWithUnit(val, 3, '');
  } else if (val >= one_million) {
    return resolveNumberWithUnit(val, 1, '');
  }
  return roundNumber(val / ten_thousand, 2) + '万';
};

interface Header {
  id: string;
  label: string;
  unit?: string;
  class: string;
}
export const HEADERS: Header[] = [
  {
    id: 'campaign',
    label: 'キャンペーン',
    class: 'p-sortable-column--variable'
  },
  {
    id: 'cpe',
    label: 'CPE',
    unit: '円',
    class: 'p-sortable-column--cpe'
  },
  {
    id: 'cpv',
    label: 'CPV',
    unit: '円',
    class: 'p-sortable-column--xsh'
  },
  {
    id: 'effectiveReachNumberOfPeople',
    label: 'Eリーチ人数',
    unit: '人',
    class: 'p-sortable-column--md'
  },
  {
    id: 'targetNumberOfCmViewing',
    label: 'T視聴回数',
    unit: '回',
    class: 'p-sortable-column--xsh'
  },
  {
    id: 'placementCost',
    label: '出稿費用',
    unit: '円',
    class: 'p-sortable-column--xsh'
  },
  {
    id: 'grp',
    label: '個人GRP',
    class: 'p-sortable-column--xsh'
  },
  {
    id: 'perCost',
    label: 'パーコスト',
    unit: '円',
    class: 'p-sortable-column--smd end'
  }
];
