import { defineStore } from 'pinia';

import {
  ReachAnalysisProject2CardDetailCostTypeEnum,
  ReachAnalysisProject2CardListCards,
  ReachAnalysisProject2CardStatusReasonEnum,
  ReachAnalysisProject2CmHeatmap,
  ReachAnalysisProject2CpeComparisonCards,
  ReachAnalysisProject2ReachAnalysisGraph,
  ReachAnalysisProject2SectionStatusCardsCalculateStatusEnum,
  ReachAnalysisProject2SectionStatusSectionEnum,
  ReachAnalysisProject2Status
} from '@/api/openapi';
import {
  CheckSelection,
  CheckSelectionList,
  ConvertToSectionEnum,
  SectionData,
  cardMax,
  initSectionData,
  judgeFuture
} from '@/store/reachAnalysis/reachAnalysisCards';

export const useReachAnalysisStore = defineStore('reachAnalysis', {
  state: () => ({
    projectStatus: undefined as ReachAnalysisProject2Status | undefined,
    cards: undefined as ReachAnalysisProject2CardListCards[] | undefined,
    checkModeSelection: undefined as CheckSelection,
    sectionData: initSectionData() as SectionData,
    isSetCardsAndStatus: false,
    cpeComparisonList: undefined as
      | ReachAnalysisProject2CpeComparisonCards[]
      | undefined,
    effectiveReachGraph: undefined as
      | ReachAnalysisProject2ReachAnalysisGraph
      | undefined,
    CmHeatmapGraph: undefined as ReachAnalysisProject2CmHeatmap | undefined,
    isCpeComparisonLoading: false,
    isEReachGraphLoading: false,
    isCmHeatmapLoading: false
  }),
  actions: {
    resetState() {
      this.projectStatus = undefined;
      this.cards = undefined;
      this.checkModeSelection = undefined;
      this.sectionData = initSectionData();
      this.cpeComparisonList = undefined;
      this.effectiveReachGraph = undefined;
      this.CmHeatmapGraph = undefined;
      this.isCpeComparisonLoading = false;
      this.isEReachGraphLoading = false;
      this.isCmHeatmapLoading = false;
    },
    setProjectStatus(status: ReachAnalysisProject2Status | undefined) {
      this.projectStatus = status;
      Object.values(CheckSelectionList).forEach(section => {
        const sectionEnum = ConvertToSectionEnum(section);
        if (!section || !sectionEnum) return;
        const newIds = this.getAggregatingIds(sectionEnum);
        const isDiff =
          newIds.toString() !==
          this.sectionData[section].aggregatingIds?.toString();
        if (isDiff || !this.sectionData[section].aggregatingIds)
          this.sectionData[section].aggregatingIds = newIds;

        this.sectionData[section].referableIds = this.getReferableIds(
          sectionEnum
        );
      });
    },
    setCards(cardList: ReachAnalysisProject2CardListCards[]) {
      this.cards = cardList;
    },
    setCheckSelection(checkModeSelection: CheckSelection) {
      this.checkModeSelection = checkModeSelection;
    },
    setDispCard(
      checkModeSelection: CheckSelection,
      dispCard: {
        mainCardId: number | undefined;
        subCardIds: number[];
      }
    ) {
      if (checkModeSelection) {
        this.sectionData[checkModeSelection].dispCard = dispCard;
      }
    },
    setSubCardIds(checkModeSelection: CheckSelection, subCardIds: number[]) {
      if (checkModeSelection) {
        this.sectionData[checkModeSelection].dispCard.subCardIds = subCardIds;
      }
    },
    setIsSetCardsAndStatus(isSet: boolean) {
      this.isSetCardsAndStatus = isSet;
    },
    setCpeComparisonList(List: ReachAnalysisProject2CpeComparisonCards[]) {
      this.cpeComparisonList = List;
    },
    setEffectiveReachGraph(graph: ReachAnalysisProject2ReachAnalysisGraph) {
      this.effectiveReachGraph = graph;
    },
    setCmHeatmapGraph(graph: ReachAnalysisProject2CmHeatmap) {
      this.CmHeatmapGraph = graph;
    },
    setIsCpeComparisonLoading(isLoading: boolean) {
      this.isCpeComparisonLoading = isLoading;
    },
    setIsEReachGraphLoading(isLoading: boolean) {
      this.isEReachGraphLoading = isLoading;
    },
    setIsCmHeatmapLoading(isLoading: boolean) {
      this.isCmHeatmapLoading = isLoading;
    }
  },
  getters: {
    card: state => (
      cardId: number | undefined
    ): ReachAnalysisProject2CardListCards | undefined => {
      if (!state.cards || state.cards.length === 0) return undefined;

      return state.cards.find(card => card.basicInfo.cardId === cardId);
    },
    mainCard: state => (): ReachAnalysisProject2CardListCards | undefined => {
      if (!state.cards || state.cards.length === 0) return undefined;
      return state.cards.find(card => card.basicInfo.isMain);
    },
    isCardMax: state => {
      return state.cards?.length === cardMax;
    },
    dispCardMax: state => {
      if (state.checkModeSelection)
        return state.sectionData[state.checkModeSelection].dispCardMax;
      return cardMax;
    },
    extendsCards: state => {
      if (!state.cards || state.cards.length === 0) return undefined;
      const getDispPlacementCost = (
        card: ReachAnalysisProject2CardListCards
      ) => {
        const isFuture = judgeFuture(card.basicInfo.endDate);
        const cpeComparison = !state.sectionData.CPE_COMPARISON.aggregatingIds?.includes(
          card.basicInfo.cardId
        )
          ? state.cpeComparisonList?.find(
              v => v.cardId === card.basicInfo.cardId
            )
          : undefined;

        const mainCard = state.cards?.find(v => v.basicInfo.isMain);
        const mainCpeComparison = !state.sectionData.CPE_COMPARISON.aggregatingIds?.includes(
          mainCard?.basicInfo.cardId ?? 0
        )
          ? state.cpeComparisonList?.find(
              v => v.cardId === mainCard?.basicInfo.cardId
            )
          : undefined;
        let dispPlacementCost: number | undefined = undefined;
        const isMainFuture = judgeFuture(mainCard?.basicInfo.endDate ?? '');
        if (
          card?.basicInfo.costType ===
            ReachAnalysisProject2CardDetailCostTypeEnum.ReferMainPerCost &&
          ((!mainCpeComparison?.grp &&
            !mainCard?.basicInfo.perCost &&
            mainCard?.basicInfo.perCost !== 0) ||
            !cpeComparison?.grp ||
            (isMainFuture && !!mainCard?.basicInfo.placementCost))
        ) {
          return undefined;
        } else if (
          card?.basicInfo.placementCost ||
          card?.basicInfo.placementCost === 0
        ) {
          dispPlacementCost = card.basicInfo.placementCost;
        } else if (
          (card?.basicInfo.perCost || card?.basicInfo.perCost === 0) &&
          !cpeComparison?.placementCost &&
          !isFuture
        ) {
          dispPlacementCost = 0;
        } else if (
          (cpeComparison?.placementCost ||
            cpeComparison?.placementCost === 0) &&
          !isFuture
        ) {
          dispPlacementCost = cpeComparison.placementCost;
        }

        return dispPlacementCost;
      };

      return state.cards.map(card => {
        return {
          ...card,
          isFuture: judgeFuture(card.basicInfo.endDate),
          dispPlacementCost: getDispPlacementCost(card)
        };
      });
    },
    areaErrorCardList: state => {
      if (!state.cards || state.cards.length === 0) return undefined;
      return state.cards
        .filter(
          card =>
            card.cardStatus.reason ===
            ReachAnalysisProject2CardStatusReasonEnum.AreaContractTerminated
        )
        .map(card => card.basicInfo.cardId);
    },
    targetErrorCardList: state => {
      if (!state.cards || state.cards.length === 0) return undefined;
      return state.cards
        .filter(
          card =>
            card.cardStatus.reason ===
              ReachAnalysisProject2CardStatusReasonEnum.TargetDeleted ||
            card.cardStatus.reason ===
              ReachAnalysisProject2CardStatusReasonEnum.TargetInvalidConditionsIncluded
        )
        .map(card => card.basicInfo.cardId);
    },
    PeriodErrorCardList: state => {
      if (!state.cards || state.cards.length === 0) return undefined;
      return state.cards
        .filter(
          card =>
            card.cardStatus.reason ===
            ReachAnalysisProject2CardStatusReasonEnum.InvalidPeriod
        )
        .map(card => card.basicInfo.cardId);
    },
    errorCardList: state => {
      if (!state.cards || state.cards.length === 0) return undefined;
      return state.cards
        .filter(
          card =>
            card.cardStatus.reason &&
            [
              ReachAnalysisProject2CardStatusReasonEnum.AreaContractTerminated,
              ReachAnalysisProject2CardStatusReasonEnum.TargetInvalidConditionsIncluded,
              ReachAnalysisProject2CardStatusReasonEnum.TargetDeleted
            ].includes(card.cardStatus.reason)
        )
        .map(card => card.basicInfo.cardId);
    },
    isSectionHidden: state => {
      if (!state.cards || state.cards.length === 0) return undefined;
      const cardIdList = state.cards?.map(v => v.basicInfo.cardId);
      const errorCardList = state.cards
        .filter(
          card =>
            card.cardStatus.reason &&
            [
              ReachAnalysisProject2CardStatusReasonEnum.AreaContractTerminated,
              ReachAnalysisProject2CardStatusReasonEnum.TargetInvalidConditionsIncluded,
              ReachAnalysisProject2CardStatusReasonEnum.TargetDeleted
            ].includes(card.cardStatus.reason)
        )
        .map(card => card.basicInfo.cardId);
      return cardIdList?.every(v => errorCardList.includes(v));
    },
    kpiStatus: state => {
      if (!state.projectStatus) return undefined;
      const status = state.projectStatus.sections.find(
        v => v.section === ReachAnalysisProject2SectionStatusSectionEnum.Kpi
      );
      return status?.cards[0].calculateStatus;
    },
    cpeComparisonStatus: state => (cardId: number) => {
      if (!state.projectStatus) return undefined;
      const status = state.projectStatus.sections.find(
        v =>
          v.section ===
          ReachAnalysisProject2SectionStatusSectionEnum.CpeComparison
      );
      return status?.cards.find(v => v.cardId === cardId)?.calculateStatus;
    },
    getAggregatingIds(): (
      section: ReachAnalysisProject2SectionStatusSectionEnum | undefined
    ) => number[] {
      return section => {
        const list: number[] = [];
        if (!this.projectStatus) return [];
        this.projectStatus.sections.map(v => {
          if (section && v.section !== section) return [];
          v.cards.map(card => {
            if (
              card.calculateStatus !==
                ReachAnalysisProject2SectionStatusCardsCalculateStatusEnum.Referable &&
              card.calculateStatus !==
                ReachAnalysisProject2SectionStatusCardsCalculateStatusEnum.Failed
            ) {
              list.push(card.cardId);
            }
          });
        });
        return list;
      };
    },
    getReferableIds(): (
      section: ReachAnalysisProject2SectionStatusSectionEnum | undefined
    ) => number[] {
      return section => {
        const list: number[] = [];
        if (!this.projectStatus) return [];
        this.projectStatus.sections.map(v => {
          if (section && v.section !== section) return [];
          v.cards.map(card => {
            if (
              card.calculateStatus ===
              ReachAnalysisProject2SectionStatusCardsCalculateStatusEnum.Referable
            )
              list.push(card.cardId);
          });
        });
        return list;
      };
    }
  }
});
