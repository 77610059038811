import { format, getHours, isAfter, isBefore, subDays } from 'date-fns';
import { storeToRefs } from 'pinia';
import { Ref, computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import {
  CompanyApi,
  CustomTargetApi,
  ReachAnalysis2Api,
  TvDataSearchApi
} from '@/api';
import {
  AreaInfoIdEnum,
  CustomTarget,
  ReachAnalysisProject2CardConditionFromCostTypeEnum,
  ReachAnalysisProject2CardDetailCalenderPeriodModeEnum,
  ReachAnalysisProject2CardDetailCmBuyingKindEnum,
  ReachAnalysisProject2CardDetailCostTypeEnum,
  ReachAnalysisProject2CardListCards,
  ReachAnalysisProject2Products,
  ReachAnalysisProject2ProductsGrpGraph,
  ReachAnalysisProject2RecommendProductsList,
  ReachAnalysisProject2SectionStatusCardsCalculateStatusEnum,
  ReachAnalysisProject2TargetSettingsTargetTypeEnum,
  SelectableAreasListAreaDivisionEnum,
  TvDataSearchCmCreative,
  WorkspaceWorkspaceTypeEnum
} from '@/api/openapi';
import { TARGET_NAME, httpCode } from '@/common/constant';
import { getAvailableDate, getAvailableEndDate } from '@/common/dates';
import { DATE_FORMAT } from '@/common/format';
import { roundNumber } from '@/common/formatter';
import { handleError } from '@/common/handleError';
import { PeriodListType, getPeriodList } from '@/common/period';
import useLoading from '@/composables/loading';
import { isDisabled } from '@/composables/validation';
import { useWorkspaceType } from '@/composables/workspace';
import { makeRequestCancelerClass } from '@/plugins/axiosCancel';
import { ROUTE_NAMES } from '@/router';
import { useAreaOptions } from '@/store/areaOptions';
import { useReachAnalysisStore } from '@/store/reachAnalysis';

interface cmCreativeDataListType extends TvDataSearchCmCreative {
  select: number;
}

export interface FormValueType {
  companyId: Ref<number>;
  areaValue: Ref<AreaInfoIdEnum | undefined>;
  periodShortcut: Ref<PeriodListType | undefined>;
  startDateStr: Ref<string | undefined>;
  endDateStr: Ref<string | undefined>;
  sponsorsIds: Ref<number[] | undefined>;
  cmProductsIds: Ref<number[] | undefined>;
  products: Ref<
    | {
        productId: number | undefined;
        cmProductId: number | undefined;
      }[]
    | undefined
  >;
  selectedCmCreativeIds: Ref<number[]>;
  cmTypeValue: Ref<'TIME' | 'SPOT' | undefined>;
  numOfEffectiveContacts: Ref<number>;
  costNum: Ref<number | undefined>;
  costType: Ref<ReachAnalysisProject2CardConditionFromCostTypeEnum>;
  targetName: Ref<string>;
  basicTargetIds: Ref<number[]>;
  customTargetId: Ref<number[]>;
  isFormDisabled: Ref<boolean>;
}

export interface ReachAnalysisForm {
  form: FormValueType;
}

export interface Recommend extends ReachAnalysisProject2RecommendProductsList {
  id: string;
  isHidden: boolean;
}

interface ExReachAnalysisProject2Products
  extends ReachAnalysisProject2Products {
  id: string;
}

type CostType =
  | ReachAnalysisProject2CardConditionFromCostTypeEnum.PlacementCost
  | ReachAnalysisProject2CardConditionFromCostTypeEnum.PerCost;

interface GoalType {
  label: string;
  id: CostType;
}

export const GOAL_TYPES: Array<GoalType> = [
  {
    label: '出稿費用',
    id: ReachAnalysisProject2CardConditionFromCostTypeEnum.PlacementCost
  },
  {
    label: 'パーコスト',
    id: ReachAnalysisProject2CardConditionFromCostTypeEnum.PerCost
  }
];

const deleteComma = (str: string): string => {
  return str.replace(/,/g, '');
};

const castNum = (str: string | undefined): number | undefined => {
  if (!str) return;
  const val = deleteComma(str);
  return Number(val);
};

type ReachAnalysisFormReturnTypes = {
  formKey: Ref<number>;
  isSettingInitVal: Ref<boolean>;
  areaValue: Ref<AreaInfoIdEnum>;
  areaSelectOptions: Ref<
    {
      areaDivision: SelectableAreasListAreaDivisionEnum;
      areas: {
        id: AreaInfoIdEnum;
        name: string;
      }[];
    }[]
  >;
  onChangeArea: (params: boolean) => Promise<boolean>;
  isLoadingOnChangeArea: Ref<boolean>;
  isLoadingAreaStore: Ref<boolean>;
  finalReportDay: Date;
  startDate: Ref<Date | undefined>;
  endDate: Ref<Date | undefined>;
  customStartDate: Ref<Date | undefined>;
  customEndDate: Ref<Date | undefined>;
  minDate: Ref<Date | undefined>;
  maxDate: Ref<Date | undefined | null>;
  isFuture: Ref<boolean>;
  scrollGrp: () => void;
  mainStart: Date;
  mainEnd: Date;
  isDisabledMain: Ref<boolean>;
  periodShortcut: Ref<PeriodListType | undefined>;
  grpRef: Ref<unknown>;
  periodDatePickerKey: Ref<number>;
  resetPeriodShortcut: () => void;
  isCmCompany: Ref<boolean>;
  cmSponsorsValue: Ref<Array<{ name: string; id: number }>>;
  fetchCmSponsors: (search: string) => Promise<unknown>;
  fetchProducts: (search: string) => Promise<unknown>;
  cmProductsValue: Ref<Array<ExReachAnalysisProject2Products>>;
  isLoadingGetCmCreatives: Ref<boolean>;
  isLoadingSetAnalysisPeriod: Ref<boolean>;
  recommendListDisplay: Ref<Recommend[]>;
  isLoadingFetchRecommend: Ref<boolean>;
  clickRecommend: (recommend: Recommend) => void;
  fetchCmCreatives: (params: unknown) => Promise<boolean>;
  isCmCreativeOptionsOpen: Ref<boolean>;
  isCmCreativeDisabled: Ref<boolean>;
  cmCreativeDataListItems: Ref<cmCreativeDataListType[]>;
  selectedCmCreativeIds: Ref<number[]>;
  notIncludedListIds: Ref<number[]>;
  grpGraphData: Ref<ReachAnalysisProject2ProductsGrpGraph[]>;
  fetchGrpGraphData: (params: unknown) => Promise<boolean>;
  isLoadingFetchGrpGraphData: Ref<boolean>;
  selectPeriodTotalGrp: Ref<number | undefined>;
  targetName: Ref<string>;
  customTargetList: Ref<
    { id: number; label: string; isEnabled: boolean | undefined }[]
  >;
  initialTargetId: Ref<number[]>;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeTarget: (target: { name: string; value: any }) => void;
  cmTypeValue: Ref<'TIME' | 'SPOT' | undefined>;
  cmTypeOptions: (
    | { label: string; value: undefined }
    | { label: string; value: string }
  )[];
  numOfEffectiveContactsItems: number[];
  numOfEffectiveContacts: Ref<number>;
  cost: Ref<string | undefined>;
  costPlaceholder: Ref<string | undefined>;
  goalType: Ref<CostType>;
  GoalType: { label: string; value: CostType }[];
  resetInputData: () => void;
  isReferMainPerCost: Ref<boolean>;
  isPerCost: Ref<boolean>;
  changeIsReferMainPerCost: () => void;
  validPeriod: Ref<string | undefined>;
  validArea: Ref<string | undefined>;
  validTarget: Ref<string | undefined>;
  validCmCreatives: Ref<string | undefined>;
  validCost: Ref<string | undefined>;
};

export const useReachAnalysisForm = (
  form: Ref<FormValueType | undefined>,
  editData?: ReachAnalysisProject2CardListCards,
  mainCard?: ReachAnalysisProject2CardListCards | undefined,
  isSubCard = false
): ReachAnalysisFormReturnTypes => {
  const router = useRouter();
  const route = useRoute();
  const { params } = route;
  const companyId = ref(Number(params.companyId));
  const reachAnalysisStore = useReachAnalysisStore();
  const thirteenWeekDates = 91; // 13週の日数
  const today = new Date();
  const hour = getHours(today);
  const formKey = ref(0);
  const isSettingInitVal = ref(true);
  const requestCancelerCmSponsors = makeRequestCancelerClass();
  const requestCancelerRecommend = makeRequestCancelerClass();
  const requestCancelerProduct = makeRequestCancelerClass();
  // 確報日
  let finalReportDay =
    0 <= hour && hour < 5 ? subDays(today, 4) : subDays(today, 3);
  // ローカル時刻からUTCに変更
  finalReportDay = new Date(format(finalReportDay, DATE_FORMAT));

  // エリア関連 /////////////////////////////////////////////////////////////
  const areaOptions = useAreaOptions();
  const areaValue = ref(AreaInfoIdEnum.Kanto);
  const {
    isLoading: isLoadingAreaStore,
    selectOptions: areaSelectOptions
  } = storeToRefs(areaOptions);

  const _onChangeArea = async (isOnlyRequired = false) => {
    const isSuccess = await setStationsCode();
    if (!isSuccess) return false;

    if (isCmCompany.value || isSubCard) {
      setRecommendList(sponsorsIds.value);
    } else {
      setRecommendList([currentCompany.value.cmSponsorId]);
    }
    if (periodShortcut.value?.key !== getPeriodList('referMain')?.key) {
      if (startDate.value && isBefore(startDate.value, minDate.value))
        startDate.value = minDate.value;
      if (endDate.value && isBefore(endDate.value, minDate.value))
        endDate.value = minDate.value;
    }
    if (
      !isOnlyRequired &&
      cmProductsIds.value &&
      cmProductsIds.value?.length > 0
    )
      fetchGrpGraphData('');

    return true;
  };
  const [isLoadingOnChangeArea, onChangeArea] = useLoading(_onChangeArea);

  // 放送局
  const stationCodes: Ref<string[]> = ref([]);
  const setStationsCode = async () => {
    try {
      const stations = await TvDataSearchApi.getTvdataSearchStations(
        areaValue.value,
        companyId.value,
        true
      );
      stationCodes.value = stations.data.flatMap(v => v.stationCodes);
      return true;
    } catch (e) {
      if (e.status === httpCode.forbidden) {
        goToError();
        return false;
      }
      handleError(e);
      return false;
    }
  };

  // 期間関連 /////////////////////////////////////////////////////////////
  const startDate: Ref<Date | undefined> = ref();
  const endDate: Ref<Date | undefined> = ref();
  const customStartDate: Ref<Date | undefined> = ref();
  const customEndDate: Ref<Date | undefined> = ref();
  const periodDatePickerKey = ref(0);
  const isFuture = ref(false);
  const periodShortcut: Ref<PeriodListType | undefined> = ref();
  const grpRef = ref();
  const startDateStr = computed(() => {
    return startDate.value ? format(startDate.value, DATE_FORMAT) : undefined;
  });
  const endDateStr = computed(() => {
    return endDate.value ? format(endDate.value, DATE_FORMAT) : undefined;
  });
  const minDate = computed(() => {
    return getAvailableDate(areaValue.value);
  });
  const maxDate = computed(() => {
    if (isFuture.value) return null;
    const areaMax = getAvailableEndDate(areaValue.value as AreaInfoIdEnum);
    return isBefore(areaMax, finalReportDay) ? areaMax : finalReportDay;
  });
  const scrollGrp = () => {
    if (grpRef.value && grpRef.value.srollGRP) grpRef.value.srollGRP();
  };
  const mainStart = mainCard?.basicInfo.startDate
    ? new Date(mainCard.basicInfo.startDate)
    : new Date();
  const mainEnd = mainCard?.basicInfo.endDate
    ? new Date(mainCard.basicInfo.endDate)
    : new Date();
  const isDisabledMain = computed(() => {
    if (!mainCard || !isSubCard) return false;
    return isAfter(minDate.value, mainStart);
  });
  const resetPeriodShortcut = () => {
    periodShortcut.value = undefined;
  };

  // 企業関連 /////////////////////////////////////////////////////////////
  const cmSponsorsValue = ref<Array<{ name: string; id: number }>>([]);
  const currentCompany = ref(); // 自社情報
  const isCmCompany = ref(false);
  const startTimeValue = '05:00';
  const endTimeValue = '28:59';
  const sponsorsIds = computed(() => {
    if (cmSponsorsValue.value) {
      return cmSponsorsValue.value.map(val => val.id);
    } else {
      return undefined;
    }
  });

  const { getCurrentWorkspaceType } = useWorkspaceType();
  const getIsCmCompany = async () => {
    const workspaceType = await getCurrentWorkspaceType();
    isCmCompany.value = workspaceType === WorkspaceWorkspaceTypeEnum.CmCompany;
  };
  const setCurrentCompany = async () => {
    await getIsCmCompany();
    currentCompany.value = await fetchCurrentCmSponsors();
    if (isSubCard) return;
    if (currentCompany.value && !editData) {
      cmSponsorsValue.value.push({
        name: currentCompany.value.cmSponsorName,
        id: currentCompany.value.cmSponsorId
      });
    }
  };

  // 自社取得
  const fetchCurrentCmSponsors = async () => {
    try {
      if (companyId.value) {
        const res = await CompanyApi.getCompaniesCompanyIdCmSponsor(
          companyId.value
        );
        return res.data;
      }
      return undefined;
    } catch (e) {
      handleError(e);
      return undefined;
    }
  };

  // 企業名取得
  const fetchCmSponsors = async (search: string) => {
    try {
      requestCancelerCmSponsors.cancel();
      const start =
        startDateStr.value ||
        format(subDays(finalReportDay, thirteenWeekDates - 1), DATE_FORMAT);
      const end = endDateStr.value || format(finalReportDay, DATE_FORMAT);
      if (stationCodes.value.length > 0 && companyId.value) {
        const getCmSponsors = await TvDataSearchApi.getTvdataSearchCmSponsorsSearch(
          stationCodes.value,
          start,
          end,
          startTimeValue,
          endTimeValue,
          companyId.value,
          undefined,
          undefined,
          sponsorsIds.value,
          undefined,
          undefined,
          search,
          { cancelToken: requestCancelerCmSponsors.token() }
        );
        return getCmSponsors.data;
      }
      return [];
    } catch (e) {
      handleError(e);
      return [];
    }
  };

  // 商品／ブランド関連 /////////////////////////////////////////////////////////////
  const cmProductsValue = ref<Array<ExReachAnalysisProject2Products>>([]);
  const cmProductsIds = computed(() => {
    if (cmProductsValue.value) {
      return cmProductsValue.value.flatMap(v => v.cmProductIds);
    } else {
      return undefined;
    }
  });

  const products = computed(() => {
    if (cmProductsValue.value) {
      const tmpProducts: {
        productId: number | undefined;
        cmProductId: number | undefined;
      }[] = [];
      cmProductsValue.value.map(v => {
        if (v.productId) {
          tmpProducts.push({
            productId: v.productId,
            cmProductId: undefined
          });
        } else {
          v.cmProductIds.map(cmProductId => {
            tmpProducts.push({
              productId: undefined,
              cmProductId: cmProductId
            });
          });
        }
      });
      return tmpProducts;
    } else {
      return undefined;
    }
  });

  const makeProductsUniqueID = (
    productId: number | undefined,
    cmProductId: number[]
  ): string => {
    const productIdVal = productId ? productId : 0;
    return (
      'productId:' + productIdVal + 'cmProductIds:' + cmProductId.join('-')
    );
  };

  // 商品／ブランド取得
  const fetchProducts = async (
    search: string
  ): Promise<ExReachAnalysisProject2Products[]> => {
    try {
      if (companyId.value) {
        const res = await ReachAnalysis2Api.getCompaniesCompanyIdReachAnalysisProjects2ProductsSearch(
          companyId.value,
          isCmCompany.value || isSubCard
            ? sponsorsIds.value
            : currentCompany.value?.cmSponsorId,
          search !== '' ? search : undefined
        );
        return res.data.map(v => ({
          ...v,
          id: makeProductsUniqueID(v.productId, v.cmProductIds)
        }));
      }
      return [];
    } catch (e) {
      handleError(e);
      return [];
    }
  };

  const _setAnalysisPeriod = async () => {
    const selectableAreaList = areaSelectOptions.value.flatMap(v =>
      v.areas.map(area => area.id)
    );
    if (
      !cmProductsIds.value ||
      cmProductsIds.value.length === 0 ||
      !selectableAreaList.includes(areaValue.value ?? '')
    ) {
      return [];
    }
    try {
      const result = await ReachAnalysis2Api.getCompaniesCompanyIdReachAnalysisProjects2ProductsAnalysisPeriod(
        companyId.value,
        cmProductsIds.value,
        areaValue.value,
        cmTypeValue.value === undefined ? 'ALL' : cmTypeValue.value,
        { cancelToken: requestCancelerProduct.token() }
      );
      if (!startDate.value && !endDate.value)
        periodShortcut.value = convertToPeriodListType(
          ReachAnalysisProject2CardDetailCalenderPeriodModeEnum.Auto
        );
      if (!startDate.value || periodShortcut.value?.key === 'custom')
        startDate.value = new Date(result.data.startDate);
      if (!endDate.value || periodShortcut.value?.key === 'custom')
        endDate.value = new Date(result.data.endDate);
      customStartDate.value = new Date(result.data.startDate);
      customEndDate.value = new Date(result.data.endDate);
    } catch (e) {
      // handleError(e);
    }
  };
  const [isLoadingSetAnalysisPeriod, setAnalysisPeriod] = useLoading(
    _setAnalysisPeriod
  );

  // 商品レコメンド /////////////////////////////////////////////////////////////
  const recommendList: Ref<Recommend[]> = ref([]);
  const recommendListDisplay = computed(() => {
    const tmpRecommendList: Recommend[] = [];
    recommendList.value.map(r => {
      if (tmpRecommendList.every(tmp => tmp.id !== r.id)) {
        tmpRecommendList.push(r);
      }
    });
    return tmpRecommendList
      .sort((a, b) => (a.grp > b.grp ? -1 : 1))
      .slice(0, 5);
  });
  const _fetchRecommend = async (
    cmSponsorIds: number[] | undefined
  ): Promise<ReachAnalysisProject2RecommendProductsList[]> => {
    requestCancelerRecommend.cancel();
    const end = format(finalReportDay, DATE_FORMAT);
    const start = format(
      subDays(finalReportDay, thirteenWeekDates - 1),
      DATE_FORMAT
    );
    const selectableAreaList = areaSelectOptions.value.flatMap(v =>
      v.areas.map(area => area.id)
    );
    if (
      !cmSponsorIds ||
      cmSponsorIds.length === 0 ||
      !selectableAreaList.includes(areaValue.value ?? '')
    )
      return [];

    try {
      const result = await ReachAnalysis2Api.getCompaniesCompanyIdReachAnalysisProjects2ProductsRecommend(
        companyId.value,
        areaValue.value,
        cmSponsorIds,
        start,
        end,
        { cancelToken: requestCancelerRecommend.token() }
      );
      return result.data.list ?? [];
    } catch (e) {
      return [];
    }
  };
  const [isFetchRecommend, fetchRecommend] = useLoading(_fetchRecommend);

  const recommendProcessingCount = ref(0);
  const setRecommendList = async (cmSponsorIds: number[] | undefined) => {
    recommendProcessingCount.value++;
    try {
      const res = await fetchRecommend(cmSponsorIds);
      const cmProductsValueIds = cmProductsValue.value.map(v => v.id);
      recommendList.value = res.map(v => {
        const id = makeProductsUniqueID(v.productId, v.cmProductIds);
        return {
          ...v,
          id: id,
          isHidden: cmProductsValueIds?.includes(id) || false
        };
      });
    } catch (error) {
      //
    } finally {
      recommendProcessingCount.value--;
    }
  };
  const isLoadingFetchRecommend = computed(() => {
    return recommendProcessingCount.value > 0 || isFetchRecommend.value;
  });

  const clickRecommend = (recommend: Recommend) => {
    const index = recommendList.value.indexOf(recommend);
    recommendList.value[index].isHidden = true;
    const product: ExReachAnalysisProject2Products = {
      productId: recommend.productId,
      cmProductIds: recommend.cmProductIds,
      name: recommend.name,
      numOfCmPlacement: 0,
      id: makeProductsUniqueID(recommend.productId, recommend.cmProductIds)
    };
    cmProductsValue.value?.push(product);
  };

  const goToError = async () => {
    await router.push({
      name: ROUTE_NAMES.error,
      params: {
        name: '権限がありません。',
        message:
          'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
        back: undefined,
        status: '403 forbidden'
      },
      force: true
    });
  };

  // CM素材関連 /////////////////////////////////////////////////////////////
  // CM素材
  const isCmCreativeOptionsOpen = ref(false);
  const isCmCreativeDisabled = ref(true);
  const cmCreativeDataListItems: Ref<cmCreativeDataListType[]> = ref([]);
  const selectedCmCreativeIds = ref<number[]>([]);
  const selectedCmCreativeDataListItems: Ref<cmCreativeDataListType[]> = ref(
    []
  );
  const notIncludedListIds = ref<number[]>([]);
  // CM素材取得
  const cmCreativesProcessingCount = ref(0);
  const _fetchCmCreatives = async () => {
    cmCreativesProcessingCount.value++;
    try {
      if (
        !cmProductsIds.value ||
        !startDateStr.value ||
        !endDateStr.value ||
        !isCmCreativeOptionsOpen.value
      )
        return false;
      if (cmProductsIds.value.length === 0) {
        cmCreativeDataListItems.value = [];
        selectedCmCreativeIds.value = [];
        selectedCmCreativeDataListItems.value = [];
        return false;
      }
      if (selectedCmCreativeIds.value.length > 0) {
        // 選択状態のリスト
        selectedCmCreativeDataListItems.value = cmCreativeDataListItems.value.filter(
          v => selectedCmCreativeIds.value.includes(v.id)
        );
      }
      // CM素材取得
      const result = await TvDataSearchApi.getTvdataSearchCmCreativesSearch(
        startDateStr.value,
        endDateStr.value,
        cmProductsIds.value,
        companyId.value,
        { cancelToken: requestCancelerProduct.token() }
      );
      cmCreativeDataListItems.value = result.data.map(v => ({
        ...v,
        select: v.id
      }));

      // 前のCM素材取得処理後に選択状態であったが最新のCM素材取得処理レスポンスの一覧には含まれてないリスト
      // (画面で赤字表示するリスト)
      const notIncludedList = selectedCmCreativeDataListItems.value.filter(
        item => !result.data.map(data => data.id).includes(item.id)
      );
      notIncludedListIds.value = notIncludedList.map(v => v.id);

      cmCreativeDataListItems.value = cmCreativeDataListItems.value
        .concat(notIncludedList)
        .sort((a, b) => a.id - b.id);
      return true;
    } catch (error) {
      return false;
    } finally {
      cmCreativesProcessingCount.value--;
    }
  };
  const [isFetchCmCreatives, fetchCmCreatives] = useLoading(_fetchCmCreatives);
  const isLoadingGetCmCreatives = computed(() => {
    return cmCreativesProcessingCount.value > 0 || isFetchCmCreatives.value;
  });

  // GRPグラフ関連 /////////////////////////////////////////////////////////////
  const grpGraphProcessingCount = ref(0);
  const grpGraphData: Ref<ReachAnalysisProject2ProductsGrpGraph[]> = ref([]);
  const _fetchGrpGraphData = async () => {
    grpGraphProcessingCount.value++;

    try {
      const selectableAreaList = areaSelectOptions.value.flatMap(v =>
        v.areas.map(area => area.id)
      );
      if (
        !cmProductsIds.value ||
        cmProductsIds.value.length === 0 ||
        !selectableAreaList.includes(areaValue.value ?? '')
      ) {
        grpGraphData.value = [];
        return false;
      }
      const result = await ReachAnalysis2Api.getCompaniesCompanyIdReachAnalysisProjects2ProductsGrpGraph(
        companyId.value,
        cmProductsIds.value,
        areaValue.value,
        cmTypeValue.value === undefined ? 'ALL' : cmTypeValue.value,
        { cancelToken: requestCancelerProduct.token() }
      );
      grpGraphData.value = result.data;
      scrollGrp();
      return true;
    } catch (e) {
      grpGraphData.value = [];
      return false;
    } finally {
      grpGraphProcessingCount.value--;
    }
  };
  const [isFetchGrpGraphData, fetchGrpGraphData] = useLoading(
    _fetchGrpGraphData
  );
  const isLoadingFetchGrpGraphData = computed(() => {
    return grpGraphProcessingCount.value > 0 || isFetchGrpGraphData.value;
  });
  const selectPeriodTotalGrp = computed(() => {
    if (
      !grpGraphData.value ||
      grpGraphData.value.length === 0 ||
      !startDate.value ||
      !endDate.value
    )
      return undefined;
    const start = format(startDate.value, DATE_FORMAT);
    let end = format(endDate.value, DATE_FORMAT);
    if (isAfter(startDate.value, finalReportDay)) {
      // 開始日が未来日の場合
      return 0;
    } else if (isAfter(endDate.value, finalReportDay)) {
      // 終了日のみが未来日の場合、確報日までのGRPを取得する
      end = format(finalReportDay, DATE_FORMAT);
    }
    const startIndex = grpGraphData.value.findIndex(v => v.date === start);
    const endIndex = grpGraphData.value.findIndex(v => v.date === end);
    if (startIndex === -1 || endIndex === -1) return undefined;
    return grpGraphData.value
      .slice(startIndex, endIndex + 1)
      .reduce((acc, cur) => acc + cur.grp, 0);
  });

  // 費用関連 /////////////////////////////////////////////////////////////
  const cost: Ref<string | undefined> = ref('');
  const costPlaceholder: Ref<string | undefined> = ref('0');
  const costNum = computed(() => {
    return castNum(cost.value);
  });
  const goalType: Ref<CostType> = ref(
    ReachAnalysisProject2CardConditionFromCostTypeEnum.PlacementCost
  );
  const GoalType = GOAL_TYPES.map(v => ({
    label: v.label,
    value: v.id
  }));
  const isPerCost = computed(() => {
    return (
      goalType.value ===
      ReachAnalysisProject2CardConditionFromCostTypeEnum.PerCost
    );
  });
  const isReferMainPerCost = ref(false);
  const mainPerCost = computed(() => {
    const mainCard = reachAnalysisStore.mainCard();
    const mainCpeComparison = reachAnalysisStore.cpeComparisonList?.find(
      v => v.cardId === mainCard?.basicInfo.cardId
    );

    const costType = mainCard?.basicInfo.costType;

    // メインカードの費用設定がパーコストの場合
    if (costType === ReachAnalysisProject2CardDetailCostTypeEnum.PerCost) {
      const perCost = mainCard?.basicInfo.perCost || mainCpeComparison?.perCost;
      return perCost?.toLocaleString();
    }

    // メインカードの費用設定が出稿費用の場合
    const isReferMainCpeComparison =
      mainCard?.basicInfo.cardId &&
      reachAnalysisStore.cpeComparisonStatus(mainCard?.basicInfo.cardId) ===
        ReachAnalysisProject2SectionStatusCardsCalculateStatusEnum.Referable;
    if (
      costType === ReachAnalysisProject2CardDetailCostTypeEnum.PlacementCost &&
      isReferMainCpeComparison
    ) {
      const placementCost =
        mainCard?.basicInfo.placementCost || mainCpeComparison?.placementCost;
      const grp = mainCpeComparison?.grp;
      if (placementCost && grp) {
        return roundNumber(placementCost / grp, 1).toString();
      }
    }

    return undefined;
  });
  const setSameMain = () => {
    if (isSubCard) {
      goalType.value =
        ReachAnalysisProject2CardConditionFromCostTypeEnum.PerCost;
      costPlaceholder.value = isReferMainPerCost.value
        ? mainPerCost.value
        : '0';
    }
    cost.value = '';
  };
  const resetInputData = () => {
    if (
      goalType.value ===
      ReachAnalysisProject2CardConditionFromCostTypeEnum.PlacementCost
    ) {
      costPlaceholder.value = '0';
    } else {
      costPlaceholder.value = isReferMainPerCost.value
        ? mainPerCost.value
        : '0';
    }
  };
  const changeIsReferMainPerCost = () => {
    setSameMain();
  };

  const costType = computed(() => {
    return isPerCost.value
      ? isReferMainPerCost.value
        ? ReachAnalysisProject2CardConditionFromCostTypeEnum.ReferMainPerCost
        : ReachAnalysisProject2CardConditionFromCostTypeEnum.PerCost
      : ReachAnalysisProject2CardConditionFromCostTypeEnum.PlacementCost;
  });

  // ターゲット /////////////////////////////////////////////////////////////
  // カスタムターゲットの有無
  const targetName: Ref<string> = ref(TARGET_NAME.individual);
  const customTargets = ref<CustomTarget[]>([]);
  const basicTargetIds = ref<number[]>([]);
  const customTargetId = ref<number[]>([]);
  const initialTargetId = ref<number[]>([]);
  const customTargetList = computed(() => {
    return customTargets.value.map(({ id, name, isEnabled }) => ({
      id,
      label: name,
      isEnabled
    }));
  });
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeTarget = (target: { name: string; value: any }) => {
    targetName.value = target.name;
    // 個人全体・世帯の場合
    if (targetName.value === TARGET_NAME.individual) {
      basicTargetIds.value = [];
      customTargetId.value = [];
      return;
    }
    // カスタムターゲットの場合
    if (targetName.value === TARGET_NAME.customTarget) {
      if (Array.isArray(target.value)) {
        if (target.value.length > 0) {
          customTargetId.value = target.value.map(v =>
            v instanceof Object ? v.id : v
          );
        } else {
          customTargetId.value = [];
        }
      } else if (target.value) {
        customTargetId.value = [target.value.id];
      }
      return;
    }
    // 基本属性ターゲットの場合
    if (target.value) basicTargetIds.value = target.value;
  };

  const convertToTargetName = (
    targetType: ReachAnalysisProject2TargetSettingsTargetTypeEnum
  ): string => {
    switch (targetType) {
      case ReachAnalysisProject2TargetSettingsTargetTypeEnum.CustomTarget:
        return TARGET_NAME.customTarget;
      case ReachAnalysisProject2TargetSettingsTargetTypeEnum.Individual:
        return TARGET_NAME.individual;
      case ReachAnalysisProject2TargetSettingsTargetTypeEnum.BasicGa12:
        return TARGET_NAME.genderAge12Type;
      case ReachAnalysisProject2TargetSettingsTargetTypeEnum.BasicGa10S:
        return TARGET_NAME.gender10Type;
      default:
        return TARGET_NAME.individual;
    }
  };

  // CM種別 /////////////////////////////////////////////////////////////
  const cmTypeValue: Ref<'TIME' | 'SPOT' | undefined> = ref(undefined);
  const cmTypeOptions = [
    { label: 'すべて', value: undefined },
    { label: 'タイム', value: 'TIME' },
    { label: 'スポット', value: 'SPOT' }
  ];

  // 有効FQ /////////////////////////////////////////////////////////////
  const numOfEffectiveContactsItems = [...Array(10)].map((_, i) => i + 1);
  const numOfEffectiveContacts = ref(1);

  // 初期処理 /////////////////////////////////////////////////////////////
  const init = async () => {
    try {
      isSettingInitVal.value = true;
      // 自社設定
      await setCurrentCompany();
      // エリアの仮初期値設定
      areaValue.value = areaSelectOptions.value[0].areas[0].id;
      // エリア情報取得
      const data = await areaOptions.fetch(route);
      if (data) {
        areaValue.value = data;
      }
      const isSuccess = await onChangeArea(true);
      if (!isSuccess) return;

      // カスタムターゲット取得
      const resTargets = await CustomTargetApi.getCompaniesCompanyIdCustomTargets(
        companyId.value
      );
      customTargets.value = resTargets.data.list;

      // 費用関連初期値
      isReferMainPerCost.value = isSubCard;

      // 初期値設定
      if (mainCard && !isDisabledMain.value) {
        startDate.value = new Date(mainCard.basicInfo.startDate);
        endDate.value = new Date(mainCard.basicInfo.endDate);
        periodShortcut.value = convertToPeriodListType(
          ReachAnalysisProject2CardDetailCalenderPeriodModeEnum.MainCard
        );
      }

      // 編集データ設定
      if (editData) setEditData();
    } catch (e) {
      handleError(e);
    } finally {
      isSettingInitVal.value = false;
    }
  };

  const convertToPeriodListType = (
    val: ReachAnalysisProject2CardDetailCalenderPeriodModeEnum
  ): PeriodListType | undefined => {
    switch (val) {
      case ReachAnalysisProject2CardDetailCalenderPeriodModeEnum.Auto:
        return getPeriodList('custom');
      case ReachAnalysisProject2CardDetailCalenderPeriodModeEnum.PreviousQuarter:
        return getPeriodList('lastQuarter');
      case ReachAnalysisProject2CardDetailCalenderPeriodModeEnum.PreviousSeason:
        return getPeriodList('lastCools');
      case ReachAnalysisProject2CardDetailCalenderPeriodModeEnum.CurrentSeason:
        return getPeriodList('currentCools');
      case ReachAnalysisProject2CardDetailCalenderPeriodModeEnum.PreviousMonth:
        return getPeriodList('lastMonth');
      case ReachAnalysisProject2CardDetailCalenderPeriodModeEnum.Previous4Weeks:
        return getPeriodList('fourWeeksAgo');
      case ReachAnalysisProject2CardDetailCalenderPeriodModeEnum.PreviousWeek:
        return getPeriodList('lastWeek');
      case ReachAnalysisProject2CardDetailCalenderPeriodModeEnum.CurrentMonth:
        return getPeriodList('currentMonth');
      case ReachAnalysisProject2CardDetailCalenderPeriodModeEnum.Last28Days:
        return getPeriodList('lastTwentyEightDays');
      case ReachAnalysisProject2CardDetailCalenderPeriodModeEnum.Last14Days:
        return getPeriodList('lastFourteenDays');
      case ReachAnalysisProject2CardDetailCalenderPeriodModeEnum.MainCard:
        return getPeriodList('referMain');
      case ReachAnalysisProject2CardDetailCalenderPeriodModeEnum.Manual:
      default:
        return undefined;
    }
  };

  const setEditData = async () => {
    if (!editData) return;

    areaValue.value = editData.basicInfo.area.areaCode as AreaInfoIdEnum;
    const selectableAreaList = areaSelectOptions.value.flatMap(v =>
      v.areas.map(area => area.id)
    );
    if (selectableAreaList.includes(areaValue.value ?? '')) {
      await onChangeArea(true);
    }

    periodShortcut.value = convertToPeriodListType(
      editData.basicInfo.calenderPeriodMode
    );
    startDate.value = new Date(editData.basicInfo.startDate);
    endDate.value = new Date(editData.basicInfo.endDate);
    isFuture.value = isAfter(endDate.value, finalReportDay);

    if (editData.basicInfo.cmSponsors) {
      cmSponsorsValue.value = editData.basicInfo.cmSponsors.map(v => ({
        name: v.cmSponsorName,
        id: v.cmSponsorId
      }));
    }

    if (editData.basicInfo.analysisProducts) {
      cmProductsValue.value = editData.basicInfo.analysisProducts.map(v => ({
        ...v,
        numOfCmPlacement: 0,
        id: makeProductsUniqueID(v.productId, v.cmProductIds)
      }));

      selectedCmCreativeIds.value = [];
      if (
        editData.basicInfo.cmCreatives &&
        editData.basicInfo.cmCreatives.length > 0
      ) {
        isCmCreativeOptionsOpen.value = true;
        cmCreativeDataListItems.value = editData.basicInfo.cmCreatives.map(
          v => ({
            id: v.cmCreativeId ?? 0,
            cmDuration: v.cmDuration ?? 0,
            cmSituation: v.cmSituation ?? '',
            cmTalent: v.cmTalent ?? '',
            cmBgm: v.cmBgm ?? '',
            cmRemarks: v.cmRemarks ?? '',
            select: v.cmCreativeId ?? 0
          })
        );
        selectedCmCreativeIds.value = editData.basicInfo.cmCreatives.map(
          v => v.cmCreativeId ?? 0
        );
      } else {
        isCmCreativeOptionsOpen.value = false;
      }
    }

    basicTargetIds.value = [];
    customTargetId.value = [];
    targetName.value = convertToTargetName(editData.targetSetting.targetType);
    if (editData.targetSetting.targets) {
      if (targetName.value === TARGET_NAME.customTarget) {
        customTargetId.value = editData.targetSetting.targets?.map(v => {
          return v.targetId ?? 0;
        });
        initialTargetId.value = customTargetId.value;
      } else {
        basicTargetIds.value = editData.targetSetting.targets?.map(v => {
          return v.targetId ?? 0;
        });
        initialTargetId.value = basicTargetIds.value;
      }
    }

    cmTypeValue.value = convertToCmType(editData.basicInfo.cmBuyingKind);
    numOfEffectiveContacts.value = editData.basicInfo.numOfEffectiveContacts;

    switch (editData.basicInfo.costType) {
      case ReachAnalysisProject2CardDetailCostTypeEnum.PlacementCost:
        goalType.value =
          ReachAnalysisProject2CardConditionFromCostTypeEnum.PlacementCost;
        cost.value = editData.basicInfo.placementCost?.toString() ?? '';
        isReferMainPerCost.value = false;
        break;
      case ReachAnalysisProject2CardDetailCostTypeEnum.PerCost:
        goalType.value =
          ReachAnalysisProject2CardConditionFromCostTypeEnum.PerCost;
        cost.value = editData.basicInfo.perCost?.toString() ?? '';
        isReferMainPerCost.value = false;
        break;
      case ReachAnalysisProject2CardDetailCostTypeEnum.ReferMainPerCost:
        goalType.value =
          ReachAnalysisProject2CardConditionFromCostTypeEnum.PerCost;
        isReferMainPerCost.value = true;
        costPlaceholder.value = mainPerCost.value;
        break;
    }

    formKey.value += 1;
    periodDatePickerKey.value += 1;
  };

  const convertToCmType = (
    cmType: ReachAnalysisProject2CardDetailCmBuyingKindEnum | undefined
  ): 'TIME' | 'SPOT' | undefined => {
    switch (cmType) {
      case ReachAnalysisProject2CardDetailCmBuyingKindEnum.Time:
        return 'TIME';
      case ReachAnalysisProject2CardDetailCmBuyingKindEnum.Spot:
        return 'SPOT';
      default:
        return undefined;
    }
  };

  // バリデーション関連 /////////////////////////////////////////////////////////////
  const validPeriod = computed(() => {
    if (!startDate.value || !endDate.value) return undefined;
    if (isBefore(startDate.value, minDate.value)) {
      return 'メインキャンペーンに選択できない期間が含まれています';
    }
    return undefined;
  });

  const validArea = computed(() => {
    const selectableAreaList = areaSelectOptions.value.flatMap(v =>
      v.areas.map(area => area.id)
    );
    if (!areaValue.value) {
      return '選択してください。';
    } else if (!selectableAreaList.includes(areaValue.value ?? '')) {
      return '契約していないエリアが含まれています。';
    } else {
      return undefined;
    }
  });

  const validTarget = computed(() => {
    if (
      targetName.value === TARGET_NAME.gender10Type ||
      targetName.value === TARGET_NAME.genderAge12Type
    ) {
      return basicTargetIds.value.length === 0
        ? 'いずれか一つは選択してください。'
        : undefined;
    } else if (targetName.value === TARGET_NAME.customTarget) {
      return customTargetId.value.length === 0
        ? 'いずれか一つは選択してください。'
        : undefined;
    } else {
      return undefined;
    }
  });

  const validCmCreatives = computed(() => {
    if (!isCmCreativeOptionsOpen.value) return undefined;
    if (
      cmCreativeDataListItems.value &&
      cmCreativeDataListItems.value.length === 0
    )
      return undefined;
    if (selectedCmCreativeIds.value.length === 0) {
      return 'CM素材が指定されていません。全ての素材を対象とする場合は、「CM素材を指定」をOFFにしてください。';
    } else {
      const check = selectedCmCreativeIds.value.some(
        id => !notIncludedListIds.value.map(nId => nId).includes(id)
      );
      if (!check) {
        return '有効なCM素材を選択してください。';
      }
    }
    return undefined;
  });

  const validCost = computed(() => {
    if (!costNum.value) {
      return undefined;
    }
    if (0 > costNum.value || costNum.value > 10_000_000_000) {
      return '0~100億の値で入力してください';
    }
    return undefined;
  });

  const isFormDisabled = isDisabled([
    validPeriod,
    validArea,
    validTarget,
    validCmCreatives,
    validCost
  ]);

  // watch関連 /////////////////////////////////////////////////////////////
  // 企業変更
  watch(
    sponsorsIds,
    () => {
      if (isCmCompany.value || isSubCard) setRecommendList(sponsorsIds.value);
    },
    { deep: true }
  );

  // 商品／ブランド変更
  const resetCmProductsRelationParam = () => {
    if (!editData) {
      startDate.value = undefined;
      endDate.value = undefined;
      // 編集画面でない場合、期間ショートカットをリセット
      periodShortcut.value = undefined;
    }
    isCmCreativeDisabled.value = true;
    isCmCreativeOptionsOpen.value = false;
    grpGraphData.value = [];
  };
  const fetchCreatives = async () => {
    await setAnalysisPeriod('');
    isCmCreativeDisabled.value = false;
    await fetchCmCreatives('');
    return true;
  };
  watch(
    cmProductsIds,
    async ids => {
      requestCancelerProduct.cancel();
      if (ids && ids?.length > 0) {
        Promise.all([fetchGrpGraphData(''), fetchCreatives()]).then(() => {
          // 上記処理中にユーザ操作で商品／ブランドが削除された場合は関連する値を初期化
          if (!cmProductsIds.value || cmProductsIds.value.length === 0)
            resetCmProductsRelationParam();
        });
      } else {
        resetCmProductsRelationParam();
      }
      const cmProductsValueIds = cmProductsValue.value.map(v => v.id);
      recommendList.value.map(v => {
        v.isHidden = cmProductsValueIds?.includes(v.id) || false;
      });
    },
    { deep: true }
  );

  // CM素材スイッチ変更
  watch(
    isCmCreativeOptionsOpen,
    isOpen => {
      if (isOpen) {
        fetchCmCreatives('');
      } else {
        cmCreativeDataListItems.value = [];
        selectedCmCreativeIds.value = [];
        selectedCmCreativeDataListItems.value = [];
        notIncludedListIds.value = [];
      }
    },
    { deep: true }
  );

  // 費用タイプ変更
  watch(cost, () => {
    if (cost.value) {
      isReferMainPerCost.value = false;
    }
  });

  // CM種別
  watch(
    cmTypeValue,
    async () => {
      await Promise.all([fetchGrpGraphData(''), setAnalysisPeriod('')]);
    },
    { deep: true }
  );

  // Formデータ /////////////////////////////////////////////////////////////
  form.value = {
    companyId,
    areaValue,
    periodShortcut,
    startDateStr,
    endDateStr,
    sponsorsIds,
    cmProductsIds,
    products,
    selectedCmCreativeIds,
    cmTypeValue,
    numOfEffectiveContacts,
    costNum,
    costType,
    targetName,
    basicTargetIds,
    customTargetId,
    isFormDisabled
  };

  init();

  return {
    formKey,
    isSettingInitVal,
    // エリア
    areaValue,
    areaSelectOptions,
    onChangeArea,
    isLoadingOnChangeArea,
    isLoadingAreaStore,
    // 期間
    finalReportDay,
    startDate,
    endDate,
    customStartDate,
    customEndDate,
    periodDatePickerKey,
    maxDate,
    minDate,
    isFuture,
    scrollGrp,
    mainStart,
    mainEnd,
    isDisabledMain,
    grpRef,
    periodShortcut,
    resetPeriodShortcut,
    // 企業
    isCmCompany,
    cmSponsorsValue,
    fetchCmSponsors,
    // 商品／ブランド
    fetchProducts,
    cmProductsValue,
    isLoadingGetCmCreatives,
    isLoadingSetAnalysisPeriod,
    // レコメンド
    recommendListDisplay,
    isLoadingFetchRecommend,
    clickRecommend,
    // CM素材
    fetchCmCreatives,
    isCmCreativeOptionsOpen,
    isCmCreativeDisabled,
    cmCreativeDataListItems,
    selectedCmCreativeIds,
    notIncludedListIds,
    // GRP
    grpGraphData,
    fetchGrpGraphData,
    isLoadingFetchGrpGraphData,
    selectPeriodTotalGrp,
    // 費用
    cost,
    costPlaceholder,
    goalType,
    GoalType,
    resetInputData,
    isReferMainPerCost,
    isPerCost,
    changeIsReferMainPerCost,
    // ターゲット
    targetName,
    customTargetList,
    initialTargetId,
    onChangeTarget,
    // CM種別
    cmTypeValue,
    cmTypeOptions,
    // 有効FQ
    numOfEffectiveContactsItems,
    numOfEffectiveContacts,
    // バリデーション
    validPeriod,
    validArea,
    validTarget,
    validCmCreatives,
    validCost
  };
};
