import { ReachAnalysis2Api } from '@/api';
import {
  ReachAnalysisProject2CardDetailCmBuyingKindEnum,
  ReachAnalysisProject2CardDetailCmCreatives,
  ReachAnalysisProject2CardDetailCostTypeEnum,
  ReachAnalysisProject2CardForm,
  ReachAnalysisProject2CardListCards,
  ReachAnalysisProject2CardStatusReasonEnum,
  ReachAnalysisProject2TargetSettingsTargetTypeEnum
} from '@/api/openapi';
import { TARGET_NAME } from '@/common/constant';
import { resolveNumber, resolveUnit } from '@/common/formatter';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import { convertBuyingKindValue } from '@/composables/reachanalysis';
import { useAreaOptions } from '@/store/areaOptions';
import { useReachAnalysisStore } from '@/store/reachAnalysis/';
import { exCard } from '@/store/reachAnalysis/reachAnalysisCards';
import { storeToRefs } from 'pinia';
import { Ref, computed, ref } from 'vue';

export interface CardCondition {
  label: string;
  productList: string;
  cmSponsorList: string;
  cmCreatives: ReachAnalysisProject2CardDetailCmCreatives[] | undefined;
  targetList: string;
  areaName: string;
  numOfEffectiveContacts: number;
  cmBuyingKind: ReachAnalysisProject2CardDetailCmBuyingKindEnum;
  condPlacementCost: string | undefined;
  start: Date;
  end: Date;
}

type eSettingCardReturnType = {
  cardData: Ref<ReachAnalysisProject2CardListCards | undefined>;
  cardDispData: Ref<ConvertToDisplayDataReturnType | undefined>;
  cardCondition: Ref<CardCondition | undefined>;
  isCardMax: Ref<boolean>;
  placementCost: Ref<number | undefined>;
  isOpenCard: Ref<boolean>;
  isOpenConditionsModal: Ref<boolean>;
  isOpenSettingCardCreateEditModal: Ref<boolean>;
  isOpenDelModal: Ref<boolean>;
  isError: Ref<boolean>;
  isCheckDisabled: Ref<boolean>;
  errorTarget: Ref<string | undefined>;
  errorArea: Ref<string | undefined>;
  errorPeriod: Ref<string | undefined>;
  conditionError: Ref<errorType[]>;
  updateCard: (params: ReachAnalysisProject2CardForm) => Promise<void>;
  isUpdatingCard: Ref<boolean>;
  copyCard: (params: unknown) => Promise<void>;
  isCopyingCard: Ref<boolean>;
  deleteCard: (params: unknown) => Promise<void>;
  isDeletingCard: Ref<boolean>;
  isLoading: Ref<boolean>;
};

type errorType = {
  key: string;
  message: string;
};

export const useSettingCard = (
  projectId: number,
  cardId: number,
  tmpCheckList: Ref<number[]>,
  isSameCheck: Ref<boolean>,
  setCards: () => void,
  setGraph: () => void,
  getProjectStatus: () => void
): eSettingCardReturnType => {
  const store = useReachAnalysisStore();
  const { checkModeSelection, sectionData } = storeToRefs(store);
  const areaOptions = useAreaOptions();
  const { selectOptions: areaSelectOptions } = storeToRefs(areaOptions);
  const isOpenCard = ref(true);
  const isOpenConditionsModal = ref(false);
  const isOpenSettingCardCreateEditModal = ref(false);
  const isOpenDelModal = ref(false);

  const cardData = computed(() => {
    return store.card(cardId);
  });

  const extendsCardData = computed(() => {
    return store.extendsCards?.find(v => v.basicInfo.cardId === cardId);
  });

  const cardDispData = computed(() => {
    return convertToDisplayData(cardData.value);
  });

  const cardCondition = computed(() => {
    const mainCard = store.mainCard();
    return convertToConditionModalData(extendsCardData.value, mainCard);
  });

  const isCardMax = computed(() => {
    return store.isCardMax;
  });

  const placementCost = computed(() => {
    return extendsCardData.value?.dispPlacementCost;
  });

  const errorArea = computed(() => {
    const areaIds = areaSelectOptions.value.flatMap(v =>
      v.areas.map(area => area.name)
    );
    if (areaIds.includes(cardDispData.value?.basicInfo.area.areaName ?? '')) {
      return undefined;
    } else {
      return '指定されたエリアは使用できません。他のエリアを編集で指定するまで、このカードは無効です。';
    }
  });

  const errorPeriod = computed(() => {
    if (
      store.PeriodErrorCardList &&
      cardDispData.value?.basicInfo.cardId &&
      store.PeriodErrorCardList.includes(cardDispData.value.basicInfo.cardId)
    )
      return '指定された期間は使用できません。メインキャンペーンに選択できない期間が含まれています。';

    return undefined;
  });

  const errorTarget = computed(() => {
    if (cardDispData.value?.cardStatus.isEnabled) return undefined;
    switch (cardDispData.value?.cardStatus.reason) {
      case ReachAnalysisProject2CardStatusReasonEnum.TargetDeleted:
        return '指定されたカスタムターゲットは削除されました。他のターゲットを編集で指定するまで、このカードは無効です。';
      case ReachAnalysisProject2CardStatusReasonEnum.TargetInvalidConditionsIncluded:
        return '指定されたカスタムターゲットに無効な条件が含まれています。他のターゲットを編集で指定、もしくは修正するまで、このカードは無効です。';
      default:
        return '';
    }
  });

  const conditionError = computed(() => {
    const errorList: errorType[] = [];
    if (errorTarget.value)
      errorList.push({ key: 'targetList', message: errorTarget.value });
    if (errorPeriod.value)
      errorList.push({ key: 'period', message: errorPeriod.value });
    if (errorArea.value)
      errorList.push({ key: 'areaName', message: errorArea.value });
    return errorList;
  });

  const isError = computed(() => {
    return !!errorArea.value || !!errorPeriod.value || !!errorTarget.value;
  });

  const isCheckDisabled = computed(() => {
    if (!checkModeSelection.value) return false;
    if (
      cardId === sectionData.value[checkModeSelection.value].dispCard.mainCardId
    )
      return true;
    if (tmpCheckList.value.includes(cardId)) return false;
    if (isError.value) return true;
    const i = isSameCheck.value ? 1 : 0;
    if (tmpCheckList.value.length + i >= store.dispCardMax) return true;
    return false;
  });

  const _updateCard = async (cardForm: ReachAnalysisProject2CardForm) => {
    isOpenConditionsModal.value = false;
    try {
      const res = await ReachAnalysis2Api.putReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(
        projectId,
        cardId,
        cardForm
      );
      if (200 <= res.status && res.status < 300) {
        setCards();
        getProjectStatus();
      } else {
        toast({
          title: '失敗',
          message: 'カードの更新に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      handleError(e);
      toast({
        title: '失敗',
        message: 'カードの更新に失敗しました',
        variant: 'error'
      });
    } finally {
      isOpenSettingCardCreateEditModal.value = false;
    }
  };
  const [isUpdatingCard, updateCard] = useLoading(_updateCard);

  const _copyCard = async () => {
    try {
      const res = await ReachAnalysis2Api.postReachAnalysisProjects2ReachAnalysisProject2IdCardsCardIdCopy(
        projectId,
        cardId
      );
      if (200 <= res.status && res.status < 300) {
        setCards();
        setGraph();
      } else {
        toast({
          title: '失敗',
          message: 'カードの複製に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      handleError(e);
      toast({
        title: '失敗',
        message: 'カードの複製に失敗しました',
        variant: 'error'
      });
    } finally {
      isOpenDelModal.value = false;
    }
  };
  const [isCopyingCard, copyCard] = useLoading(_copyCard);

  const _deleteCard = async () => {
    isOpenConditionsModal.value = false;
    try {
      const res = await ReachAnalysis2Api.deleteReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(
        projectId,
        cardId
      );
      if (200 <= res.status && res.status < 300) {
        setCards();
        setGraph();
      } else {
        toast({
          title: '失敗',
          message: 'カードの削除に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      handleError(e);
      toast({
        title: '失敗',
        message: 'カードの削除に失敗しました',
        variant: 'error'
      });
    } finally {
      isOpenDelModal.value = false;
    }
  };
  const [isDeletingCard, deleteCard] = useLoading(_deleteCard);

  const isLoading = computed(() => {
    return (
      isUpdatingCard.value ||
      store.getAggregatingIds(undefined).includes(cardId)
    );
  });

  return {
    cardData,
    cardDispData,
    cardCondition,
    isCardMax,
    placementCost,
    isOpenCard,
    isOpenConditionsModal,
    isOpenSettingCardCreateEditModal,
    isOpenDelModal,
    isError,
    isCheckDisabled,
    errorTarget,
    errorArea,
    errorPeriod,
    conditionError,
    updateCard,
    isUpdatingCard,
    copyCard,
    isCopyingCard,
    deleteCard,
    isDeletingCard,
    isLoading
  };
};

interface ConvertToDisplayDataReturnType
  extends ReachAnalysisProject2CardListCards {
  dispFQ: string;
  dispProducts: string;
  dispProductsSub: string;
  dispCmCreatives: string;
  dsipSponsors: string | undefined;
  dsipSponsorsSub: string;
  dispTarget: string | undefined;
  dispPeriod: string;
  dispCmBuyingKind: string;
}

const convertToDisplayData = (
  card: ReachAnalysisProject2CardListCards | undefined
): ConvertToDisplayDataReturnType | undefined => {
  if (!card) return undefined;

  const dsipSponsors =
    card.basicInfo.cmSponsors && card.basicInfo.cmSponsors.length > 0
      ? card.basicInfo.cmSponsors[0].cmSponsorName
      : undefined;
  const dsipSponsorsSub =
    card.basicInfo.cmSponsors && card.basicInfo.cmSponsors.length > 1
      ? 'ほか' + (card.basicInfo.cmSponsors.length - 1) + '件'
      : '';
  const dispTarget =
    card.targetSetting.targetType ===
    ReachAnalysisProject2TargetSettingsTargetTypeEnum.Individual
      ? 'ターゲット:' + TARGET_NAME.individual
      : card.targetSetting.targets.length > 0
      ? 'ターゲット:' +
        card.targetSetting.targets.map(v => v.targetName).join(',')
      : undefined;
  const dispPeriod =
    card.basicInfo.startDate.replace(/-/g, '/') +
    '〜' +
    card.basicInfo.endDate.replace(/-/g, '/');

  return {
    ...card,
    dispFQ: 'R' + card.basicInfo.numOfEffectiveContacts + '+',
    dispProducts: card.basicInfo.analysisProducts[0].name,
    dispProductsSub:
      card?.basicInfo.analysisProducts &&
      card?.basicInfo.analysisProducts.length > 1
        ? 'ほか' + (card.basicInfo.analysisProducts.length - 1) + '件'
        : '',
    dispCmCreatives:
      card.basicInfo.cmCreatives && card.basicInfo.cmCreatives.length > 0
        ? 'CM素材 指定あり'
        : '',
    dsipSponsors: dsipSponsors,
    dsipSponsorsSub: dsipSponsorsSub,
    dispTarget: dispTarget,
    dispPeriod: dispPeriod,
    dispCmBuyingKind: convertBuyingKindValue(card.basicInfo.cmBuyingKind)
  };
};

const convertToConditionModalData = (
  card: exCard | undefined,
  mainCard: ReachAnalysisProject2CardListCards | undefined
): CardCondition | undefined => {
  if (!card || !mainCard) return undefined;

  const products = card.basicInfo.analysisProducts.map(v => v.name);
  const cmSponsors = card.basicInfo.cmSponsors?.map(v => v.cmSponsorName) || [];
  let target =
    card.targetSetting.targetType ===
    ReachAnalysisProject2TargetSettingsTargetTypeEnum.Individual
      ? [TARGET_NAME.individual]
      : card.targetSetting.targets.map(v => v.targetName ?? '');
  if (
    !card.cardStatus.isEnabled &&
    card.cardStatus.reason ===
      ReachAnalysisProject2CardStatusReasonEnum.TargetDeleted
  ) {
    target = ['削除されたターゲットです'];
  }

  let placementCost =
    card.dispPlacementCost || card.dispPlacementCost === 0
      ? resolveNumber(card.dispPlacementCost, 2) +
        resolveUnit(card.dispPlacementCost, '円')
      : undefined;
  if (
    !card.dispPlacementCost &&
    card.dispPlacementCost !== 0 &&
    (card.basicInfo.placementCost ||
      card.basicInfo.placementCost === 0 ||
      card.basicInfo.perCost ||
      card.basicInfo.perCost === 0 ||
      card.basicInfo.costType ===
        ReachAnalysisProject2CardDetailCostTypeEnum.ReferMainPerCost)
  )
    placementCost = '─';

  const data: CardCondition = {
    label: card.basicInfo.label,
    productList: products.join('、'),
    cmSponsorList: cmSponsors.join('、'),
    cmCreatives: card.basicInfo.cmCreatives,
    targetList: target.join('、'),
    areaName: card.basicInfo.area.areaName,
    numOfEffectiveContacts: card.basicInfo.numOfEffectiveContacts,
    cmBuyingKind: card.basicInfo.cmBuyingKind,
    condPlacementCost: placementCost,
    start: new Date(card.basicInfo.startDate),
    end: new Date(card.basicInfo.endDate)
  };

  return data;
};
