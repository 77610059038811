import { ReachAnalysis2Api } from '@/api';
import {
  ReachAnalysisProject2CmHeatmapCardSettingForm,
  ReachAnalysisProject2CmHeatmapCardSettingFormCellValueEnum,
  ReachAnalysisProject2CmHeatmapCardSettingFormPositionEnum,
  ReachAnalysisProject2CmHeatmapCardSettingFormVisualEnum,
  ReachAnalysisProject2CmHeatmapCardThresholdFormPositionEnum,
  ReachAnalysisProject2CmHeatmapCardThresholdFormVisualEnum,
  ReachAnalysisProject2CmHeatmapCardThresholdResetFormPositionEnum,
  ReachAnalysisProject2CmHeatmapCardThresholdResetFormVisualEnum,
  ReachAnalysisProject2CmHeatmapCells,
  ReachAnalysisProject2CmHeatmapSetting,
  ReachAnalysisProject2CmHeatmapSettingCards,
  ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum,
  ReachAnalysisProject2CmHeatmapSettingCardsPositionEnum,
  ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum,
  ReachAnalysisProject2CmHeatmapSettingThresholds,
  ReachAnalysisProject2CmHeatmapSettingThresholdsVisualEnum,
  ReachAnalysisProject2CmHeatmapStationReport
} from '@/api/openapi';
import { DATE_FORMAT } from '@/common/format';
import { toast } from '@/components/ui/Toast';
import { useStationColor } from '@/composables/campaign/brand_lift/colors';
import useLoading from '@/composables/loading';
import {
  convertToCSV,
  downloadCsv,
  getCSVHeader
} from '@/composables/reachanalysis/csv';
import { STATION_CODE_MAP } from '@/composables/realtimerating';
import { useReachAnalysisStore } from '@/store/reachAnalysis';
import { format } from 'date-fns';
import { Ref, computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export const HeatmapChart = {
  chart: {
    type: 'heatmap',
    plotBorderWidth: 1,
    borderColor: 'var(--dark-25-hex-color)',
    height: 440,
    marginRight: 0,
    marginTop: 20,
    style: {
      borderColor: 'var(--dark-25-hex-color)'
    }
  },
  labels: {
    align: 'right',
    x: -10,
    y: 0
  },
  title: {
    text: null
  },
  tooltip: {
    enabled: true
  },
  legend: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  exporting: {
    buttons: undefined
  }
};

export const HeatmapXAxis = {
  opposite: true,
  labels: {
    style: {
      fontSize: '8px'
    },
    x: 0,
    y: -6
  },
  plotLines: [
    { color: 'var(--dark-60-color)', width: 1, value: 6.5, zIndex: 4 },
    { color: 'var(--dark-60-color)', width: 1, value: 13.5, zIndex: 4 },
    { color: 'var(--dark-60-color)', width: 1, value: 20.5, zIndex: 4 },
    { color: 'var(--dark-60-color)', width: 1, value: 27.5, zIndex: 4 }
  ]
};

interface HeatMapData {
  y: number;
  x: number;
  cell: ReachAnalysisProject2CmHeatmapCells;
  cellValue: number | string | undefined;
  color: string;
  labelColor: string;
  stationNetworkCode: string;
  stationDisplayName: string;
  dayOfWeek: number;
}

interface Station extends ReachAnalysisProject2CmHeatmapStationReport {
  color: string;
}

export interface HeatMap {
  cardId: number;
  data: HeatMapData[];
  stations: Station[];
  thresholdList:
    | {
        from: number;
        to: number;
        color: string;
      }[]
    | null;
  thresholdListMax: number;
  cardLabel: string;
  cardColor: string;
  isEventhreshold: boolean;
  errorMessage: string | undefined;
}

type CmHeatMapSectionReturnTypes = {
  isSingle: Ref<boolean>;
  primary: Ref<HeatMap>;
  primarySettings: Ref<HeatmapSettings>;
  secondary: Ref<HeatMap>;
  secondarySettings: Ref<HeatmapSettings>;
  isFetchCmHeatMapSettings: Ref<boolean>;
  heatmapkey: Ref<number>;
  updateCmHeatMapSettings: (val: {
    isPrimary: boolean;
    cardId: number;
    settings: HeatmapSettings;
  }) => Promise<void>;
  isUpdateCmHeatMapSettings: Ref<boolean>;
  updateCmHeatMapSingle: (params: unknown) => Promise<void>;
  isUpdateCmHeatMapSingle: Ref<boolean>;
  updateThreshold: (params: {
    isPrimary: boolean;
    cardId: number;
    visual: string;
    thresholdValues: number[];
  }) => Promise<void>;
  isUpdateThreshold: Ref<boolean>;
  resetThreshold: (params: {
    isPrimary: boolean;
    cardId: number;
    visual: string;
  }) => Promise<void>;
  isResetThreshold: Ref<boolean>;
  fileName: string;
  exportCsv: () => void;
};

interface VALUE_TYPE {
  id: string;
  label: string;
  cellValue: ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum;
}
export const VALUE_TYPE_LIST: VALUE_TYPE[] = [
  {
    id: 'trp',
    label: 'TRP',
    cellValue: ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum.Trp
  },
  {
    id: 'numberOfPublication',
    label: '出稿本数',
    cellValue:
      ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum.NumberOfPublication
  },
  {
    id: 'viewingRate',
    label: '視聴率',
    cellValue:
      ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum.ViewingRate
  },
  {
    id: 'contentRatio',
    label: '含有率',
    cellValue:
      ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum.ContentRatio
  }
];

interface BGCOLOR_TYPE {
  id: string;
  label: string;
  visual: ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum;
  $isDisabled?: boolean;
  content?: string;
}
export const BGCOLOR_TYPE_LIST: BGCOLOR_TYPE[] = [
  {
    id: 'viewingRate',
    label: '視聴率',
    visual: ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum.ViewingRate,
    $isDisabled: false
  },
  {
    id: 'contentRatio',
    label: '含有率',
    visual: ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum.ContentRatio,
    $isDisabled: false,
    content: '含有率'
  },
  {
    id: 'targetScore',
    label: 'スコア',
    visual: ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum.Score,
    $isDisabled: false
  }
];

export interface HeatmapSettings {
  selectValue: {
    id: string;
    label: string;
    cellValue: ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum;
  };
  bGColor: {
    id: string;
    label: string;
    visual: ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum;
  };
  isOnlyPublication: boolean;
  thresholds: ReachAnalysisProject2CmHeatmapSettingThresholds[];
}

const colorList = [
  '#FAFDFF',
  '#F1F8FF',
  '#CBE3FE',
  '#AFD1FA',
  '#08519C',
  '#08306B'
];

export const week = ['月', '火', '水', '木', '金', '土', '日'] as const;

const timeList = Array.from(Array(24).keys(), x => x + 5);
const getTimeIndex = (time: number) => {
  return timeList.findIndex(v => v === time);
};
export const timeCategories = timeList.map(v => `${v}:00`);

const errToast = (msg: string) => {
  toast({
    title: '失敗',
    message: msg,
    variant: 'error'
  });
};

export const convertToVisual = (
  visual: ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum
): ReachAnalysisProject2CmHeatmapSettingThresholdsVisualEnum => {
  switch (visual) {
    case ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum.Score:
      return ReachAnalysisProject2CmHeatmapSettingThresholdsVisualEnum.Score;
    case ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum.ViewingRate:
      return ReachAnalysisProject2CmHeatmapSettingThresholdsVisualEnum.ViewingRate;
    case ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum.ContentRatio:
      return ReachAnalysisProject2CmHeatmapSettingThresholdsVisualEnum.ContentRatio;
  }
};

const convertVisual = (
  visual: ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum
): ReachAnalysisProject2CmHeatmapCardSettingFormVisualEnum => {
  switch (visual) {
    case ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum.Score:
      return ReachAnalysisProject2CmHeatmapCardSettingFormVisualEnum.Score;
    case ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum.ViewingRate:
      return ReachAnalysisProject2CmHeatmapCardSettingFormVisualEnum.ViewingRate;
    case ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum.ContentRatio:
      return ReachAnalysisProject2CmHeatmapCardSettingFormVisualEnum.ContentRatio;
  }
};

const convertCellValue = (
  cellValue: ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum
): ReachAnalysisProject2CmHeatmapCardSettingFormCellValueEnum => {
  switch (cellValue) {
    case ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum.Trp:
      return ReachAnalysisProject2CmHeatmapCardSettingFormCellValueEnum.Trp;
    case ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum.NumberOfPublication:
      return ReachAnalysisProject2CmHeatmapCardSettingFormCellValueEnum.NumberOfPublication;
    case ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum.ViewingRate:
      return ReachAnalysisProject2CmHeatmapCardSettingFormCellValueEnum.ViewingRate;
    case ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum.ContentRatio:
      return ReachAnalysisProject2CmHeatmapCardSettingFormCellValueEnum.ContentRatio;
  }
};

const convertToVisualForThreshold = (
  val: string
): ReachAnalysisProject2CmHeatmapCardThresholdFormVisualEnum => {
  switch (val) {
    case 'SCORE':
      return ReachAnalysisProject2CmHeatmapCardThresholdFormVisualEnum.Score;
    case 'VIEWING_RATE':
      return ReachAnalysisProject2CmHeatmapCardThresholdFormVisualEnum.ViewingRate;
    case 'CONTENT_RATIO':
      return ReachAnalysisProject2CmHeatmapCardThresholdFormVisualEnum.ContentRatio;
  }
  return ReachAnalysisProject2CmHeatmapCardThresholdFormVisualEnum.ContentRatio;
};

const convertToVisualForResetThreshold = (
  val: string
): ReachAnalysisProject2CmHeatmapCardThresholdResetFormVisualEnum => {
  switch (val) {
    case 'SCORE':
      return ReachAnalysisProject2CmHeatmapCardThresholdResetFormVisualEnum.Score;
    case 'VIEWING_RATE':
      return ReachAnalysisProject2CmHeatmapCardThresholdResetFormVisualEnum.ViewingRate;
    case 'CONTENT_RATIO':
      return ReachAnalysisProject2CmHeatmapCardThresholdResetFormVisualEnum.ContentRatio;
  }
  return ReachAnalysisProject2CmHeatmapCardThresholdResetFormVisualEnum.ContentRatio;
};

export const useCmHeatMapSection = (): CmHeatMapSectionReturnTypes => {
  const route = useRoute();
  const projectId = route.params.projectId?.toString();
  const store = useReachAnalysisStore();
  const heatmapkey = ref(0);
  const isSingle = ref(false);
  const setting: Ref<ReachAnalysisProject2CmHeatmapSetting | undefined> = ref();
  const weekCount = week.length;
  const { getStationColorByCode } = useStationColor();

  const errorCards = computed(() => {
    let errorList = store.errorCardList ?? [];
    errorList = errorList.concat(store.PeriodErrorCardList ?? []);
    return errorList;
  });

  const getErrorMessage = (cardId: number) => {
    if (
      store.sectionData.CM_HEATMAP.aggregatingIds?.includes(cardId) ||
      !errorCards.value?.includes(cardId)
    )
      return undefined;
    if (store.areaErrorCardList?.includes(cardId))
      return 'カードで指定されたエリアは使用できません。他のエリアを指定して下さい。';
    if (store.targetErrorCardList?.includes(cardId))
      return 'カードで指定されたターゲットは使用できません。他のターゲットを指定して下さい。';
    if (store.PeriodErrorCardList?.includes(cardId))
      return 'カードで指定された期間は使用できません。';

    return undefined;
  };

  // 左右のHMの設定エリア比較
  const getIsSortStations = () => {
    const primaryId = store.sectionData.CM_HEATMAP.dispCard.subCardIds[0];
    const secondaryId = store.sectionData.CM_HEATMAP.dispCard.subCardIds[1];
    if (getErrorMessage(primaryId) || getErrorMessage(secondaryId))
      return false;
    const primaryCard = store.card(primaryId);
    const secondaryCard = store.card(secondaryId);
    if (!primaryCard?.basicInfo.area || !secondaryCard?.basicInfo.area)
      return false;
    return (
      primaryCard.basicInfo.area.areaCode !==
      secondaryCard.basicInfo.area.areaCode
    );
  };

  const getThresholdList = (settings: HeatmapSettings) => {
    if (settings.thresholds.length === 0) return null;
    const threshold =
      settings.thresholds.find(
        v => v.visual === convertToVisual(settings.bGColor.visual)
      )?.thresholdValues ?? [];

    return colorList.map((color, i) => ({
      from: threshold[i],
      to: threshold[i + 1],
      color: color
    }));
  };

  const checkShowValue = (
    cell: ReachAnalysisProject2CmHeatmapCells,
    settings: HeatmapSettings
  ) => {
    // データがない場合はハイフン表示としたいため、無条件trueを返却する
    if (
      Object.keys(cell)
        .filter(key => key !== 'broadcastTimezone')
        .every(key => cell[key] === null) &&
      !settings.isOnlyPublication
    ) {
      return true;
    }

    const cellValue = settings.selectValue.cellValue;
    const {
      NumberOfPublication,
      Trp
    } = ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum;

    const hasPublication =
      cell.numberOfPublication != null && cell.numberOfPublication > 0;

    if (cellValue === NumberOfPublication || cellValue === Trp) {
      return hasPublication;
    }

    return !settings.isOnlyPublication || hasPublication;
  };

  const checkShowColor = (
    cell: ReachAnalysisProject2CmHeatmapCells,
    settings: HeatmapSettings
  ) => {
    const hasPublication =
      cell.numberOfPublication != null && cell.numberOfPublication > 0;
    return !settings.isOnlyPublication || hasPublication;
  };

  const getCellColor = (
    cell: ReachAnalysisProject2CmHeatmapCells,
    settings: HeatmapSettings,
    thresholdList: null | { color: string; from: number; to: number }[]
  ): { bg: string; label: string } => {
    let labelColor = 'var(--primary-color)'; // セルのラベルカラー
    if (!checkShowColor(cell, settings))
      return { bg: 'var(--contrast-color)', label: labelColor };
    if (!thresholdList || thresholdList.length === 0)
      return { bg: colorList[0], label: labelColor };
    const val = cell[settings.bGColor.id];
    let color = colorList[0];
    thresholdList.map((v, i) => {
      if (val >= v.from) {
        color = v.color;
        if (i >= 4) {
          labelColor = 'var(--contrast-color)';
        }
      }
    });
    return { bg: color, label: labelColor };
  };

  const dummyCode = 'dummy';
  const dummyVal = -1;
  const getHeatmapData = (dispCardId: number, settings: HeatmapSettings) => {
    const errorMessage = getErrorMessage(dispCardId);
    // 集計中またはカードがエラーの場合空データを返す
    const aggregatingIds = store.sectionData.CM_HEATMAP.aggregatingIds ?? [];
    if (aggregatingIds.includes(dispCardId) || errorMessage) {
      return {
        cardId: dispCardId,
        data: [] as HeatMapData[],
        stations: [] as Station[],
        thresholdList: [] as
          | {
              from: number;
              to: number;
              color: string;
            }[]
          | null,
        thresholdListMax: 0,
        cardLabel: '',
        cardColor: '',
        isEventhreshold: false,
        errorMessage: errorMessage
      };
    }

    // ヘッダーで表示するカード情報を設定
    const heatMapCard = store.CmHeatmapGraph?.cards.find(
      v => v.cardId === dispCardId
    );
    const cardData = store.cards?.find(v => v.basicInfo.cardId === dispCardId);

    // 閾値を設定
    const thresholdList = getThresholdList(settings);
    const thresholdListMax = thresholdList
      ? thresholdList[thresholdList.length - 1]?.to
      : 0;

    let data: HeatMapData[] = [];
    let stations: Station[] = [];
    let dummyIndex: number | undefined = undefined;
    if (heatMapCard) {
      // 局情報を設定
      const isSort = getIsSortStations();
      if (isSort) {
        // 左右のHMのエリアが異なる場合は関東の局順番に並び替える
        STATION_CODE_MAP.SAAS_KANTO.map(kantoCode => {
          const station = heatMapCard.stationReport.find(
            v => kantoCode === v.stationNetworkCode
          );
          if (station)
            stations.push({
              ...station,
              color: getStationColorByCode(station.stationNetworkCode)
            });
        });
      } else {
        stations = heatMapCard.stationReport.map(v => ({
          ...v,
          color: getStationColorByCode(v.stationNetworkCode)
        }));
      }

      if (stations.length === 4) {
        dummyIndex = 3;
        // 4局の場合ダミー局を追加
        const dummyStations = {
          stationNetworkCode: dummyCode,
          stationDisplayName: 'テレビ東京系列',
          stationOrder: 4,
          numberOfPublication: dummyVal,
          numberOfViewing: dummyVal,
          numberOfTargetViewing: dummyVal,
          grp: dummyVal,
          trp: dummyVal,
          targetContentRatio: dummyVal,
          color: getStationColorByCode('TX')
        };
        stations.splice(dummyIndex, 0, dummyStations);
      }

      // セルの情報を設定
      heatMapCard.heatmap.map(wDataList => {
        const stationIndex = stations.findIndex(
          station => station.stationNetworkCode === wDataList.stationNetworkCode
        );

        if (stationIndex < 0) return; // stationsに存在しない局はスキップ

        const addX = stationIndex * weekCount;
        wDataList.heatmapPerDayOfWeek.map(wData => {
          wData.cells.map(cell => {
            data.push({
              x: addX + wData.dayOfWeek - 1,
              y: getTimeIndex(cell.broadcastTimezone),
              cell: cell,
              cellValue: checkShowValue(cell, settings)
                ? cell[settings.selectValue.id]
                : '',
              color: '',
              labelColor: 'var(--primary-color)',
              stationNetworkCode: wDataList.stationNetworkCode,
              stationDisplayName: stations[stationIndex].stationDisplayName,
              dayOfWeek: wData.dayOfWeek
            });
          });
        });
      });
    }

    // 閾値が一律同じ値かどうか
    const isEventhreshold =
      thresholdList === null ||
      thresholdList.every(v => v.from === thresholdList[0].from);

    // セルの色を設定
    data = data.map(v => {
      const cl = getCellColor(v.cell, settings, thresholdList);
      return {
        ...v,
        color: cl.bg,
        labelColor: cl.label
      };
    });

    if (dummyIndex) {
      // ダミー局のセル情報を追加
      const addX = dummyIndex * weekCount;
      heatMapCard?.heatmap[0].heatmapPerDayOfWeek.map(wData => {
        wData.cells.map(cell => {
          data.push({
            x: addX + wData.dayOfWeek - 1,
            y: getTimeIndex(cell.broadcastTimezone),
            cell: {
              ...cell,
              numberOfPublication: dummyVal,
              trp: dummyVal,
              viewingRate: dummyVal,
              contentRatio: dummyVal,
              targetScore: dummyVal
            },
            cellValue: settings.isOnlyPublication ? '' : undefined,
            color: 'var(--dark-5-color)',
            labelColor: '',
            stationNetworkCode: dummyCode,
            stationDisplayName: '',
            dayOfWeek: wData.dayOfWeek
          });
        });
      });
    }

    return {
      cardId: dispCardId,
      data: data,
      stations: stations,
      thresholdList: thresholdList,
      thresholdListMax: thresholdListMax,
      cardLabel: cardData?.basicInfo.label ?? '',
      cardColor: cardData?.basicInfo.color ?? '',
      isEventhreshold: isEventhreshold,
      errorMessage: undefined
    };
  };

  const initSettings = {
    selectValue: VALUE_TYPE_LIST[0],
    bGColor: BGCOLOR_TYPE_LIST[0],
    isOnlyPublication: false,
    thresholds: []
  };

  const primarySettings: Ref<HeatmapSettings> = ref({ ...initSettings });
  const primary = computed(() => {
    const primaryId = store.sectionData.CM_HEATMAP.dispCard.subCardIds[0];
    return getHeatmapData(primaryId, primarySettings.value);
  });
  const secondarySettings: Ref<HeatmapSettings> = ref({ ...initSettings });
  const secondary = computed(() => {
    const secondaryId = store.sectionData.CM_HEATMAP.dispCard.subCardIds[1];
    return getHeatmapData(secondaryId, secondarySettings.value);
  });

  const mHeatmapSettingCards: Ref<ReachAnalysisProject2CmHeatmapSettingCards[]> = ref(
    []
  );

  const getSetting = (
    baseSettings: ReachAnalysisProject2CmHeatmapSettingCards
  ) => {
    const cellValue = VALUE_TYPE_LIST.find(
      v => v.cellValue === baseSettings.cellValue
    );
    const bGColor = BGCOLOR_TYPE_LIST.find(
      v => v.visual === baseSettings.visual
    );
    const thresholds = baseSettings.thresholds;
    return {
      selectValue: cellValue,
      bGColor: bGColor,
      thresholds: thresholds
    };
  };

  // 設定されている背景色の閾値が全て同じ値の場合は背景色を視聴率に変更
  const checkThresholds = (
    isPrimary: boolean,
    cardId: number,
    settings: HeatmapSettings
  ): HeatmapSettings => {
    const currentBGColorVisual = settings.bGColor.visual;
    const currentThreshold = settings.thresholds.find(
      v => v.visual === convertToVisual(currentBGColorVisual)
    );
    let newSettings = settings;
    if (
      currentThreshold?.thresholdValues.every(
        v => v === currentThreshold?.thresholdValues[0]
      )
    ) {
      const viewingRateBGColor = BGCOLOR_TYPE_LIST.find(
        v => v.id === 'viewingRate'
      );
      newSettings = {
        ...settings,
        bGColor: viewingRateBGColor || settings.bGColor
      };
      // settingsの背景色を視聴率で更新
      updateCmHeatMapSettings({ isPrimary, cardId, settings });
    }
    return newSettings;
  };

  const setSettings = () => {
    if (!setting.value || !primary.value) return true;

    isSingle.value = setting.value.isSingle;

    const primarySetting = setting.value.cards.find(
      v =>
        v.cardId === primary.value.cardId &&
        v.position ===
          ReachAnalysisProject2CmHeatmapSettingCardsPositionEnum.Primary
    );
    if (primarySetting) {
      const tmpSet = getSetting(primarySetting);
      if (tmpSet.selectValue)
        primarySettings.value.selectValue = tmpSet.selectValue;
      if (tmpSet.bGColor) primarySettings.value.bGColor = tmpSet.bGColor;
      primarySettings.value.thresholds = tmpSet.thresholds
        ? tmpSet.thresholds
        : [];
      primarySettings.value = checkThresholds(
        true,
        primary.value.cardId,
        primarySettings.value
      );

      heatmapkey.value += 1;
    }

    if (!secondary.value.cardId) return;
    const secondarySetting = setting.value.cards.find(
      v =>
        v.cardId === secondary.value.cardId &&
        v.position ===
          ReachAnalysisProject2CmHeatmapSettingCardsPositionEnum.Secondary
    );
    if (secondarySetting) {
      const tmpSet = getSetting(secondarySetting);
      if (tmpSet.selectValue)
        secondarySettings.value.selectValue = tmpSet.selectValue;
      if (tmpSet.bGColor) {
        secondarySettings.value.bGColor = tmpSet.bGColor;
      }
      secondarySettings.value.thresholds = tmpSet.thresholds
        ? tmpSet.thresholds
        : [];
      secondarySettings.value = checkThresholds(
        false,
        secondary.value.cardId,
        secondarySettings.value
      );

      heatmapkey.value += 1;
    }
  };

  const _fetchCmHeatMapSettings = async () => {
    try {
      if (!projectId) return;

      const res = await ReachAnalysis2Api.getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettings(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        setting.value = res.data;
        mHeatmapSettingCards.value = setting.value.cards;
        setSettings();
      } else {
        errToast('CM出稿ヒートマップの設定取得に失敗しました');
      }
    } catch (e) {
      errToast('CM出稿ヒートマップの設定取得に失敗しました');
    }
  };
  const [isFetchCmHeatMapSettings, fetchCmHeatMapSettings] = useLoading(
    _fetchCmHeatMapSettings
  );

  const _updateCmHeatMapSettings = async (val: {
    isPrimary: boolean;
    cardId: number;
    settings: HeatmapSettings;
  }) => {
    try {
      if (!projectId) return;

      const position = val.isPrimary
        ? ReachAnalysisProject2CmHeatmapCardSettingFormPositionEnum.Primary
        : ReachAnalysisProject2CmHeatmapCardSettingFormPositionEnum.Secondary;

      const reachAnalysisProject2CmHeatmapCardSetting: ReachAnalysisProject2CmHeatmapCardSettingForm = {
        position,
        visual: convertVisual(val.settings.bGColor.visual),
        cellValue: convertCellValue(val.settings.selectValue.cellValue)
      };
      const res = await ReachAnalysis2Api.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardId(
        Number(projectId),
        val.cardId,
        reachAnalysisProject2CmHeatmapCardSetting
      );
      if (200 <= res.status && res.status < 300) {
        //
      } else {
        errToast('CM出稿ヒートマップの設定更新に失敗しました');
      }
    } catch (e) {
      errToast('CM出稿ヒートマップの設定更新に失敗しました');
    }
  };
  const [isUpdateCmHeatMapSettings, updateCmHeatMapSettings] = useLoading(
    _updateCmHeatMapSettings
  );

  const _updateCmHeatMapSingle = async () => {
    try {
      if (!projectId) return;
      const res = await ReachAnalysis2Api.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsSingle(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        isSingle.value = true;
        heatmapkey.value += 1;
      } else {
        errToast('CM出稿ヒートマップの片表示設定更新に失敗しました');
      }
    } catch (e) {
      errToast('CM出稿ヒートマップの片表示設定更新に失敗しました');
    }
  };
  const [isUpdateCmHeatMapSingle, updateCmHeatMapSingle] = useLoading(
    _updateCmHeatMapSingle
  );

  const _updateThreshold = async (val: {
    isPrimary: boolean;
    cardId: number;
    visual: string;
    thresholdValues: number[];
  }) => {
    try {
      if (!projectId) return;

      const position = val.isPrimary
        ? ReachAnalysisProject2CmHeatmapCardThresholdFormPositionEnum.Primary
        : ReachAnalysisProject2CmHeatmapCardThresholdFormPositionEnum.Secondary;
      const form = {
        position,
        visual: convertToVisualForThreshold(val.visual),
        thresholdValues: val.thresholdValues
      };

      if (val.isPrimary) {
        primarySettings.value.thresholds = primarySettings.value.thresholds.map(
          v => {
            if (v.visual == val.visual) {
              v.thresholdValues = val.thresholdValues;
            }
            return v;
          }
        );
      } else {
        secondarySettings.value.thresholds = secondarySettings.value.thresholds.map(
          v => {
            if (v.visual == val.visual) {
              v.thresholdValues = val.thresholdValues;
            }
            return v;
          }
        );
      }

      const res = await ReachAnalysis2Api.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValue(
        Number(projectId),
        val.cardId,
        form
      );
      if (200 <= res.status && res.status < 300) {
        //
      } else {
        errToast('CM出稿ヒートマップの凡例更新に失敗しました');
      }
    } catch (e) {
      errToast('CM出稿ヒートマップの凡例更新に失敗しました');
    }
  };
  const [isUpdateThreshold, updateThreshold] = useLoading(_updateThreshold);

  const _resetThreshold = async (val: {
    isPrimary: boolean;
    cardId: number;
    visual: string;
  }) => {
    try {
      if (!projectId) return;

      const position = val.isPrimary
        ? ReachAnalysisProject2CmHeatmapCardThresholdResetFormPositionEnum.Primary
        : ReachAnalysisProject2CmHeatmapCardThresholdResetFormPositionEnum.Secondary;
      const form = {
        position,
        visual: convertToVisualForResetThreshold(val.visual)
      };

      const res = await ReachAnalysis2Api.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValueReset(
        Number(projectId),
        val.cardId,
        form
      );
      if (200 <= res.status && res.status < 300) {
        fetchCmHeatMapSettings('');
      } else {
        errToast('CM出稿ヒートマップのリセットに失敗しました');
      }
    } catch (e) {
      errToast('CM出稿ヒートマップのリセットに失敗しました');
    }
  };
  const [isResetThreshold, resetThreshold] = useLoading(_resetThreshold);

  const today = format(new Date(), DATE_FORMAT);
  const fileName = `TVAL_RAHM_${projectId}_${today}`;
  const sep = ',';
  const lineFeed = '\n';

  const getCsvData = (data: HeatMapData[], key: string) => {
    return timeList.map(time => {
      const row = [time + ':00'];
      const line = data.filter(v => v.cell.broadcastTimezone === time);
      line.map(v => {
        // ダミー局の値は空白表示
        const val = v.cell[key] === dummyVal ? '' : v.cell[key];
        row.push(val);
        if (v.dayOfWeek === 7) row.push('');
      });
      row.pop();
      return row;
    });
  };

  const convertDummy = (val: number) => {
    return val === dummyVal ? '' : val;
  };

  const exportCsv = () => {
    const dispIds = isSingle.value
      ? [primary.value.cardId]
      : [primary.value.cardId, secondary.value.cardId];
    const dispCards = store.extendsCards?.filter(v =>
      dispIds.includes(v.basicInfo.cardId)
    );

    const header = getCSVHeader('CM出稿ヒートマップ', dispCards ?? []);
    const titles = lineFeed;

    const body: string[][] = [[]];
    const baseBodyDataList = isSingle.value
      ? [{ set: primarySettings.value, val: primary.value }]
      : [
          { set: primarySettings.value, val: primary.value },
          { set: secondarySettings.value, val: secondary.value }
        ];

    baseBodyDataList.map(({ set, val }, i) => {
      const stations = val.stations.map(v => v.stationDisplayName);
      const stationCodeList = val.stations.map(v => v.stationNetworkCode);
      const dataStationsHeader = [''];
      stations.map((s, i) => {
        const n = i === stations.length - 1 ? weekCount - 1 : weekCount;
        dataStationsHeader.push(s + sep.repeat(n));
      });
      const dataDayOfWeekHeader: string[] = [];
      stations.map(() => {
        dataDayOfWeekHeader.push('');
        week.map(v => {
          dataDayOfWeekHeader.push(v);
        });
      });

      if (i != 0) body.push([]);

      // 画面で表示している局順にデータを並び替える
      const sortData: HeatMapData[] = [];
      stationCodeList.map(code => {
        const filterCode = val.data.filter(v => v.stationNetworkCode === code);
        filterCode.map(v => {
          sortData.push(v);
        });
      });

      // 背景色データ
      body.push([convertToCSV('■' + val.cardLabel)]);
      body.push([set.bGColor.label + '（画面グラフの背景に設定しているもの）']);
      body.push(dataStationsHeader);
      body.push(dataDayOfWeekHeader);
      getCsvData(sortData, set.bGColor.id).map(v => {
        body.push(v);
      });
      body.push([]);

      // グラフで表示している数値データ
      body.push([
        set.selectValue.label + '（画面グラフの数値に設定しているもの）'
      ]);
      body.push(dataStationsHeader);
      body.push(dataDayOfWeekHeader);
      getCsvData(sortData, set.selectValue.id).map(v => {
        body.push(v);
      });
      body.push([]);

      // 放送局別指標
      body.push(['■放送局別指標']);
      body.push([sep + stations.join(sep)]);
      body.push([
        '出稿本数' +
          sep +
          val.stations.map(v => convertDummy(v.numberOfPublication)).join(sep)
      ]);
      body.push([
        '視聴回数' +
          sep +
          val.stations.map(v => convertDummy(v.numberOfViewing)).join(sep)
      ]);
      body.push([
        'ターゲット視聴回数' +
          sep +
          val.stations.map(v => convertDummy(v.numberOfTargetViewing)).join(sep)
      ]);
      body.push([
        'GRP' + sep + val.stations.map(v => convertDummy(v.grp)).join(sep)
      ]);
      body.push([
        'TRP' + sep + val.stations.map(v => convertDummy(v.trp)).join(sep)
      ]);
      body.push([
        'ターゲット含有率' +
          sep +
          val.stations.map(v => convertDummy(v.targetContentRatio)).join(sep)
      ]);
    });

    const csvData = header.join('') + titles + body.join(lineFeed);
    downloadCsv(fileName + '.csv', csvData);
  };

  const init = async () => {
    fetchCmHeatMapSettings('');
  };

  watch(
    () => secondary.value.cardId,
    id => {
      if (id) isSingle.value = false;
    }
  );

  const cardsLength = computed(() => store.cards?.length ?? 0);
  watch(cardsLength, (newVal, oldVal) => {
    // カード追加、削除時
    if (newVal && oldVal && newVal !== oldVal) {
      fetchCmHeatMapSettings('');
    }
  });

  watch(
    () => store.sectionData.CM_HEATMAP.dispCard,
    () => {
      // 表示カード変更時
      fetchCmHeatMapSettings('');
    },
    { deep: true }
  );

  watch(
    () => store.sectionData.CM_HEATMAP.aggregatingIds,
    ids => {
      if (ids) fetchCmHeatMapSettings('');
    },
    { deep: true }
  );

  watch(
    () => store.CmHeatmapGraph,
    () => {
      setSettings();
    },
    { deep: true }
  );

  init();

  return {
    isSingle,
    primary,
    primarySettings,
    secondary,
    secondarySettings,
    isFetchCmHeatMapSettings,
    heatmapkey,
    updateCmHeatMapSettings,
    isUpdateCmHeatMapSettings,
    updateCmHeatMapSingle,
    isUpdateCmHeatMapSingle,
    updateThreshold,
    isUpdateThreshold,
    resetThreshold,
    isResetThreshold,
    fileName,
    exportCsv
  };
};
