import { DATE_FORMAT } from '@/common/format';
import { roundNumber } from '@/common/formatter';
import { CHART_OPTIONS_BASE } from '@/composables/reachanalysis/baseChart';
import {
  convertToCSV,
  downloadCsv,
  getCSVHeader
} from '@/composables/reachanalysis/csv';
import { useReachAnalysisStore } from '@/store/reachAnalysis';
import { format } from 'date-fns';
import { Ref, computed, ref } from 'vue';
import { useRoute } from 'vue-router';

type EffectiveReachSectionReturnTypes = {
  graphTitle: Ref<string>;
  graphData: Ref<
    {
      name: string;
      data: {
        y: number;
        x: number;
      }[];
      color: string;
      order: number;
    }[]
  >;
  header: Ref<{
    effectiveReachNumberOfPeople: number | undefined;
    effectiveReachRate: number | undefined;
    area: string;
    target: string;
  }>;
  activeRadio: Ref<
    | {
        id: string;
        label: string;
        unit: string;
      }
    | undefined
  >;
  activeRadioId: Ref<string>;
  activeSelect: Ref<{
    id: string;
    label: string;
  }>;
  fileName: string;
  companyId: string;
  projectId: string;
  exportCsv: () => void;
};

const axiosStyle = {
  fontSize: '10px',
  lineHeight: 1.3,
  color: '#42525299'
};

export const EFFECTIVE_REACH_CHART = {
  ...CHART_OPTIONS_BASE,
  chart: {
    ...CHART_OPTIONS_BASE.chart,
    height: 440,
    type: 'line',
    marginLeft: 45
  },
  plotOptions: {
    ...CHART_OPTIONS_BASE.plotOptions,
    series: {
      lineWidth: 3,
      marker: {
        enabled: false,
        symbol: 'circle'
      }
    }
  },
  yAxis: {
    ...CHART_OPTIONS_BASE.yAxis,
    labels: {
      style: axiosStyle
    }
  },
  xAxis: {
    ...CHART_OPTIONS_BASE.xAxis,
    showLastLabel: false,
    minPadding: 0,
    maxPadding: 0.08,
    min: 0,
    tickLength: 0,
    reserveSpace: false,
    labels: {
      style: axiosStyle,
      reserveSpace: false
    },
    title: {
      text: 'GRP',
      align: 'high',
      y: 4,
      style: axiosStyle
    }
  }
};

export const EFFECTIVE_REACH_TYPE = {
  numberOfPeople: {
    id: 'numberOfPeople',
    label: '人数',
    title: 'Eリーチ人数',
    unit: '万人'
  },
  rate: {
    id: 'rate',
    label: '率',
    title: 'Eリーチ率',
    unit: '%'
  }
} as const;
export const EFFECTIVE_REACH_RADIO_TYPE = Object.values(EFFECTIVE_REACH_TYPE);

export const GRP_TYPE = [
  {
    id: 'individual',
    label: '個人'
  },
  {
    id: 'household',
    label: '世帯'
  }
];

const getColor = (color: string | undefined) => {
  switch (color) {
    case 'BLUE':
      return '#6b90df';
    case 'SKY':
      return '#65aec1';
    case 'GREEN':
      return '#99d77b';
    case 'YELLOW':
      return '#fad379';
    case 'ORANGE':
      return '#f3ac82';
    case 'PINK':
      return '#f39cbc';
    case 'PURPLE':
      return '#8b82b9';
    default:
      return '#6b90df';
  }
};

export const useEffectiveReachSection = (): EffectiveReachSectionReturnTypes => {
  const store = useReachAnalysisStore();
  const route = useRoute();
  const companyId = route.params.companyId.toString();
  const projectId = route.params.projectId?.toString();
  const activeRadioId = ref(EFFECTIVE_REACH_RADIO_TYPE[0].id);
  const activeSelect = ref(GRP_TYPE[0]);
  const activeRadio = computed(() => {
    return EFFECTIVE_REACH_RADIO_TYPE.find(v => v.id === activeRadioId.value);
  });

  const mainCard = computed(() => {
    return store.mainCard();
  });
  const errorCards = computed(() => {
    let errorList = store.errorCardList ?? [];
    errorList = errorList.concat(store.PeriodErrorCardList ?? []);
    return errorList;
  });

  const baseGraphData = computed(() => {
    const subCardIds = store.sectionData.EFFECTIVE_REACH.dispCard.subCardIds;
    const aggregatingIds =
      store.sectionData.EFFECTIVE_REACH.aggregatingIds ?? [];
    if (!subCardIds) return undefined;
    return store.effectiveReachGraph?.cards.filter(
      v =>
        (v.isMain || subCardIds.includes(v.cardId)) &&
        !aggregatingIds.includes(v.cardId) &&
        !errorCards.value?.includes(v.cardId)
    );
  });

  const header = computed(() => {
    let area = '';
    let target = '';
    const mainCardId = store.effectiveReachGraph?.cards.find(v => v.isMain);
    const isCalculating = mainCardId
      ? store.sectionData.EFFECTIVE_REACH.aggregatingIds?.includes(
          mainCardId.cardId
        )
      : false;

    if (baseGraphData.value && baseGraphData.value[0]) {
      const baseArea = baseGraphData.value[0].areaName;
      area = baseGraphData.value?.every(v => v.areaName === baseArea)
        ? baseArea
        : '混在';
      const baseTarget = baseGraphData.value[0].targetNames;
      target = baseGraphData.value?.every(v => v.targetNames === baseTarget)
        ? baseTarget
        : '混在';
    }
    const effectiveReachNumberOfPeople =
      isCalculating ||
      (mainCard.value?.basicInfo.cardId &&
        errorCards.value?.includes(mainCard.value?.basicInfo.cardId))
        ? undefined
        : store.effectiveReachGraph?.effectiveReachNumberOfPeople;
    const effectiveReachRate =
      isCalculating ||
      (mainCard.value?.basicInfo.cardId &&
        errorCards.value?.includes(mainCard.value?.basicInfo.cardId))
        ? undefined
        : store.effectiveReachGraph?.effectiveReachRate;

    return {
      effectiveReachNumberOfPeople: effectiveReachNumberOfPeople,
      effectiveReachRate: effectiveReachRate,
      area: area,
      target: target
    };
  });

  const graphData = computed(() => {
    if (!baseGraphData.value) return [];

    const showData: {
      name: string;
      data: { y: number; x: number }[];
      color: string;
      order: number;
    }[] = [];

    baseGraphData.value?.map((v, i) => {
      const data = v[activeSelect.value.id] as {
        grp: number;
        numberOfPeople: number;
        rate: number;
      }[];
      if (!data) return;
      const cardData = store.cards?.find(
        card => card.basicInfo.cardId === v.cardId
      );
      showData[i] = {
        name: cardData?.basicInfo.label ?? '',
        data: data.map(val => ({
          y: val[activeRadioId.value] ?? 0,
          x: val.grp ?? 0
        })),
        color: getColor(cardData?.basicInfo.color),
        order: cardData?.basicInfo.displayOrder ?? -1
      };
    });

    showData.sort((a, b) => a.order - b.order);

    return showData.length > 0
      ? showData
      : [
          {
            name: '',
            data: [],
            color: '',
            order: 0
          }
        ];
  });

  const graphTitle = computed(() => {
    const active = activeRadio.value;
    const baseTitle = active?.title + '（' + active?.unit + '）';
    let numOfEffectiveContacts = '';
    if (baseGraphData.value && baseGraphData.value[0]) {
      const base = baseGraphData.value[0].numOfEffectiveContacts;
      const val = baseGraphData.value?.every(
        v => v.numOfEffectiveContacts === base
      )
        ? base
        : '混在';
      numOfEffectiveContacts = '有効FQ:' + val;
    }
    return baseTitle + '  ' + numOfEffectiveContacts;
  });

  const today = format(new Date(), DATE_FORMAT);
  const fileName = `TVAL_RAER_${projectId}_${today}`;

  const exportCsv = () => {
    const sep = ',';
    const lineFeed = '\n';
    const dispGrapIds = baseGraphData.value?.map(v => v.cardId);
    const dispCards = store.extendsCards?.filter(v =>
      dispGrapIds?.includes(v.basicInfo.cardId)
    );
    const header = getCSVHeader('エフェクティブリーチ', dispCards ?? []);

    const cardCount = dispCards?.length ?? 0;
    const cardMax = store.sectionData.EFFECTIVE_REACH.dispCardMax;
    let seps = sep;
    [...Array(cardMax - 1)].map(() => {
      seps = seps + sep;
    });

    let titles =
      sep +
      '累計GRP' +
      '（' +
      activeSelect.value.label +
      '）' +
      seps +
      'エフェクティブリーチ人数（人）' +
      seps +
      'エフェクティブリーチ率（%）' +
      seps +
      'CPE（円）' +
      seps.slice(0, -1) +
      lineFeed +
      '日付';
    [...Array(4)].map(() => {
      let labels = '';
      for (let i = 0; i < cardMax; i++) {
        const label = dispCards?.[i]?.basicInfo.label;
        const str = label ? convertToCSV(label) : '─';
        labels = labels + sep + str;
      }
      titles = titles + labels;
    });
    titles = titles + lineFeed;

    const body: string[][] = [[]];
    if (baseGraphData.value) {
      const baseGrap = baseGraphData.value;
      const graphData = dispCards
        ?.map(v => {
          return baseGrap.find(data => data.cardId === v.basicInfo.cardId);
        })
        .filter(
          // undefinedを除外
          (item): item is Exclude<typeof item, undefined> => item !== undefined
        );

      if (!graphData) return;

      const dataList = graphData.map(v => v[activeSelect.value.id]); // CSVでは個人のみ出力
      const daysList = dataList.map(v => v.length);
      const days = Math.max(...daysList);

      let emptySeps = '';
      [...Array(cardMax - cardCount)].map(() => {
        emptySeps = emptySeps + sep;
      });

      for (let i = 0; i < days; i++) {
        body[i] = [];
        body[i].push(i + 1 + '日目');

        const grps = dataList
          .map(v =>
            v[i]?.grp || v[i]?.grp === 0 ? `"${roundNumber(v[i].grp, 7)}"` : '─'
          )
          .join(sep);
        body[i].push(grps + emptySeps);

        const numberOfPeople = dataList
          .map(v =>
            v[i]?.numberOfPeople || v[i]?.numberOfPeople === 0
              ? `"${roundNumber(v[i].numberOfPeople, 1)}"`
              : '─'
          )
          .join(sep);
        body[i].push(numberOfPeople + emptySeps);

        const rate = dataList
          .map(v =>
            v[i]?.rate || v[i]?.rate === 0
              ? `"${roundNumber(v[i].rate, 7)}"`
              : '─'
          )
          .join(sep);
        body[i].push(rate + emptySeps);

        const cpe = dataList
          .map(v => {
            const cpe = v[i]?.cpe;
            if (cpe || cpe === 0) {
              return `"${roundNumber(cpe, 7)}"`;
            } else {
              return '─';
            }
          })
          .join(sep);
        body[i].push(cpe + emptySeps);
      }
    }

    const csvData = header.join('') + titles + body.join(lineFeed);
    downloadCsv(fileName + '.csv', csvData);
  };

  return {
    header,
    graphTitle,
    graphData,
    activeRadio,
    activeRadioId,
    activeSelect,
    fileName,
    companyId,
    projectId,
    exportCsv
  };
};
