export const CHART_OPTIONS_BASE = {
  chart: {
    height: 290,
    animation: true,
    spacingLeft: 0,
    marginTop: 28
  },
  series: {
    borderWidth: 0
  },
  yAxis: {
    gridLineColor: '#e6e6e6',
    gridLineWidth: 0.5,
    offset: -10,
    title: {
      reserveSpace: false,
      align: 'high',
      offset: 0,
      rotation: 0,
      y: -15,
      x: 1,
      style: {
        'text-anchor': 'start',
        fontSize: 10
      }
    }
  },
  xAxis: {
    title: {
      align: 'high',
      rotation: 0,
      style: {
        'text-anchor': 'end',
        fontSize: 10
      }
    }
  },
  legend: {
    enabled: true,
    useHTML: true,
    itemStyle: {
      fontSize: 12,
      color: '#425252',
      fontWeight: 'normal',
      verticalalign: 'top'
    },

    squareSymbol: false,
    symbolHeight: 6,
    symbolWidth: 32,
    symbolRadius: 0
  },
  subtitle: {
    align: 'right',
    y: 5,
    style: {
      fontSize: 10
    }
  },
  plotOptions: {
    series: {
      pointWidth: 20
    }
  },
  title: false,
  credits: {
    enabled: false
  },
  exporting: {
    enabled: true,
    buttons: false,
    allowHTML: true,
    chartOptions: {
      chart: {
        marginTop: 50,
        spacingLeft: 15
      },
      title: {
        style: {
          fontSize: '12px'
        }
      },
      credits: {
        enabled: true,
        text: '© TVAL Switch Media, Inc.'
      },
      xAxis: {
        max: null
      }
    }
  }
};

export interface PlotBands {
  color: string;
  from: number;
  to: number;
}
