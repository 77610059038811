import { ReachAnalysisProject2TargetSettingsTargetTypeEnum } from '@/api/openapi';
import { TARGET_NAME } from '@/common/constant';
import { DATE_FORMAT_JA, DATE_FORMAT_JA_FULL } from '@/common/format';
import { roundNumber } from '@/common/formatter';
import { convertBuyingKindValue } from '@/composables/reachanalysis';
import { exCard } from '@/store/reachAnalysis/reachAnalysisCards';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';

export const convertToCSV = (str: string): string => {
  return '"' + str.replace(/"/g, '""') + '"';
};

export const getCSVHeader = (title: string, dispCards: exCard[]): string[] => {
  const header: string[] = [];
  const sep = ',';
  const lineFeed = '\n';
  const commaJa = '、';

  header.push('TVAL - ' + title + lineFeed);

  header.push(sep);
  header.push(
    dispCards.map(v => convertToCSV('■' + v.basicInfo.label)).join(sep) +
      lineFeed
  );

  header.push('商品／ブランド:' + sep);
  header.push(
    dispCards
      ?.map(v =>
        convertToCSV(
          v.basicInfo.analysisProducts.map(p => p.name).join(commaJa)
        )
      )
      .join(sep) + lineFeed
  );

  header.push('CM素材:' + sep);
  const creattives = dispCards.map(v =>
    v.basicInfo.cmCreatives && v.basicInfo.cmCreatives.length > 0
      ? v.basicInfo.cmCreatives
          .map(c => c.cmSituation + '(' + c.cmCreativeId + ')')
          .join(commaJa)
      : 'すべて'
  );
  header.push(creattives.join(sep) + lineFeed);

  header.push('企業:' + sep);
  const sponsors = dispCards.map(v =>
    v.basicInfo.cmSponsors && v.basicInfo.cmSponsors.length > 0
      ? v.basicInfo.cmSponsors.map(c => c.cmSponsorName).join(commaJa)
      : '─'
  );
  header.push(sponsors.join(sep) + lineFeed);

  header.push('ターゲット:' + sep);
  const targets = dispCards.map(v =>
    v.targetSetting.targetType ===
    ReachAnalysisProject2TargetSettingsTargetTypeEnum.Individual
      ? [TARGET_NAME.individualAndHousehold]
      : v.targetSetting.targets.map(t => t.targetName).join(commaJa)
  );
  header.push(targets.join(sep) + lineFeed);

  header.push('エリア:' + sep);
  header.push(
    dispCards.map(v => v.basicInfo.area.areaName).join(sep) + lineFeed
  );

  header.push('期間:' + sep);
  const period = dispCards?.map(
    v =>
      format(new Date(v.basicInfo.startDate), DATE_FORMAT_JA) +
      '〜' +
      format(new Date(v.basicInfo.endDate), DATE_FORMAT_JA)
  );
  header.push(period.join(sep) + lineFeed);

  header.push('有効接触回数:' + sep);
  header.push(
    dispCards
      .map(v => v.basicInfo.numOfEffectiveContacts + '回以上')
      .join(sep) + lineFeed
  );

  header.push('CM種別:' + sep);
  header.push(
    dispCards
      .map(v => convertBuyingKindValue(v.basicInfo.cmBuyingKind))
      .join(sep) + lineFeed
  );

  header.push('出稿費用:' + sep);
  header.push(
    dispCards
      .map(v =>
        v.dispPlacementCost || v.dispPlacementCost === 0
          ? `"${roundNumber(v.dispPlacementCost)}"` + '円'
          : '─'
      )
      .join(sep) +
      lineFeed +
      lineFeed
  );

  header.push('レポート作成日時:' + sep);
  header.push(
    format(new Date(), DATE_FORMAT_JA_FULL, { locale: ja }) + lineFeed
  );

  header.push(
    'データ提供元:' + sep + '"Switch Media, Inc."' + lineFeed + lineFeed
  );

  return header;
};

export const downloadCsv = (fileName: string, csvData: string): void => {
  const link = document.createElement('a');
  const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvData], {
    type: 'text/csv'
  });

  link.setAttribute('download', fileName);
  link.setAttribute('href', window.webkitURL.createObjectURL(blob));
  link.click();
};
